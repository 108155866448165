import React, { useState, useEffect } from 'react';


import { Box } from '@mui/material';
import {
	PeopleAltOutlined as UsersIcon,
	SettingsSuggestOutlined as PropertyIcon,
	ApartmentOutlined as MarketIcon,
	CorporateFareOutlined as DepartmentIcon,
	MapsHomeWorkOutlined as DealershipIcon,
} from '@mui/icons-material';



import { useAppContext } from '../contexts/appContext';
import Users from '../comps/admin/Users';
import Properties from '../comps/admin/Properties';
import Markets from '../comps/admin/Markets';
import Departments from '../comps/admin/Departments';
import Dealerships from '../comps/admin/Dealerships';
import NoAccess from '../comps/NoAccess';


const tabs = [
	{ name: 'Users', icon: <UsersIcon sx={{ mr: 2 }} /> },
	{ name: 'Properties', icon: <PropertyIcon sx={{ mr: 2 }} /> },
	{ name: 'Markets', icon: <MarketIcon sx={{ mr: 2 }} /> },
	{ name: 'Departments', icon: <DepartmentIcon sx={{ mr: 2 }} /> },
	{ name: 'Dealerships', icon: <DealershipIcon sx={{ mr: 2 }} /> },
]


const Admin = () => {
	// ----- CONTEXTS
	const { userInfo } = useAppContext();

	// ----- STATES
	const [activeTabAdmin, setActiveTabAdmin] = useState(0);
	const [activeAdminPage, setactiveAdminPage] = useState(<Users />);


	// ----- EFFECTS
	useEffect(() => {
		if (!userInfo.admin) {
			setActiveTabAdmin(<NoAccess />);
		} else {
			setActiveTabAdmin(0);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		switch (activeTabAdmin) {
			case 0:
				setactiveAdminPage(<Users />)
				break;
			case 1:
				setactiveAdminPage(<Properties />)
				break;
			case 2:
				setactiveAdminPage(<Markets />)
				break;
			case 3:
				setactiveAdminPage(<Departments />)
				break;
			case 4:
				setactiveAdminPage(<Dealerships />)
				break;
			case 5:
				setactiveAdminPage(<NoAccess />)
				break;
			default:
				setactiveAdminPage(<NoAccess />)
		}
	}, [activeTabAdmin]);


	// ----- FUNCTIONS
	const handleAdminTabClick = (ix) => {

		setActiveTabAdmin(ix);
	}

	return (
		<Box sx={{ height: '100%', width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>

			{/* ADMIN NAVS */}

			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
				{
					userInfo.admin &&
					tabs.map((tab, index) => {
						return (
							<Box key={index} onClick={() => handleAdminTabClick(index)}
								sx={{
									p: 1,
									width: '20%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									'&:hover': { cursor: 'pointer', bgcolor: '#C0C0C0' },
									bgcolor: '#E5E7E9',
									borderBottom: activeTabAdmin === index ? '4px solid #1976d2' : '4px solid #E5E7E9',
									color: activeTabAdmin === index ? '#1976d2' : 'inherit'
								}}
							>
								{tab.icon}{tab.name}
							</Box>
						)
					})
				}
			</Box>


			{/* ADMIN SELECTED CONTENT */}
			<Box sx={{ p: 3, flexGrow: 1 }}>{activeAdminPage}</Box>
        </Box>
	);
};

export default Admin;
