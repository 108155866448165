import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import { AppBar, Avatar, Backdrop, Box, CircularProgress, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import {
    Home as HomeIcon,
    AccountCircleOutlined as UserCircleIcon
} from '@mui/icons-material';


import logo from '../assets/logo.png';
import { useAppContext } from '../contexts/appContext';
import { msalInstance } from '../msal/config';
import Login from '../pages/Login';
import axios from 'axios';


const Layout = ({ children }) => {
    const navigate = useNavigate();

    // ----- CONTEXTS
    const { user, setUser, userInfo, loginLoading, setLoginLoading, activeTabMain, setActiveTabMain } = useAppContext();


    // ----- STATES
    const [anchorEl, setAnchorEl] = useState(null);


    // ----- EFFECTS
    useEffect(() => {
        if (!user) getSessionUser();
        if (window.location.pathname === '/admin') setActiveTabMain(3);

        // eslint-disable-next-line
    }, []);


    // ----- FUNCTIONS
    const getSessionUser = () => {
        setLoginLoading(true);
        const sessionUser = window.sessionStorage.getItem('user');
        setUser(JSON.parse(sessionUser));
    }

    const handleHomeClick = () => {
        setActiveTabMain(0)
        navigate('/');
    }

    const handleLogoutClick = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'put',
            url: `${url}/addAppLoginLogout/?code=${key}`,
            data: {
                email: user,
                activity: 'logout'
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            window.sessionStorage.removeItem('msal.interaction.status');
        setAnchorEl(null);
        msalInstance.logoutRedirect();
        });
    }


	return (
		<>
            {
                user && userInfo ?
                <Container
                    maxWidth='xl'
                    disableGutters
                    sx={{
                        height: '100vh',
                        width: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <AppBar elevation={0} position='static' sx={{ bgcolor: 'black' }}>
                        <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Box sx={{ width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {
                                    activeTabMain === 3 ?
                                        <HomeIcon
                                            onClick={handleHomeClick}
                                            sx={{ '&:hover': { cursor: 'pointer' } }}
                                        />
                                    :
                                        <>
                                            <Avatar src={logo} />
                                            <Typography variant='h5' sx={{ ml: 1 }}>Inchcape</Typography>
                                        </>
                                }
                            </Box>
                            <Typography variant='h4' onClick={handleHomeClick} sx={{ '&:hover': { cursor: 'pointer' } }}>WIP REPORT</Typography>
                            <Box sx={{ width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Typography>{userInfo.name}</Typography>
                                {/* <UseCircleIcon sx={{ ml: 1 }} /> */}
                                <IconButton
                                    color='inherit'
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                    sx={{ ml: 1 }}
                                >
                                    <UserCircleIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                    keepMounted
                                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                                    open={Boolean(anchorEl)}
                                    onClose={() => {setAnchorEl(null)}}
                                    sx={{ mt: '45px' }}
                                >
                                    <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    
                    <Box sx={{ width: '100%', flexGrow: 1, border: '1px solid lightgray', borderBottom: 0 }}>
                        {children}
                    </Box>
                </Container>
                :
                <Login />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loginLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
			
		</>
	);
};

export default Layout;
