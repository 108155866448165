import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';


import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import {
    Close as CloseIcon,
    History as HistoryIcon,
    AddCircle as AddIcon,
    Search as SearchIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Circle as CircleIcon
} from '@mui/icons-material';
import { Box, Container } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';


import { useAppContext } from '../contexts/appContext';
import Notif from './Notif';


const WipView = ({ open, row, onCloseCallback, updateCallback }) => {
    const commentsCols = [
        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: ({ row }) => {
                return [
                    <IconButton onClick={() => handleViewAddCommentClick('view', row)}>
                        <SearchIcon />
                    </IconButton>
                ]
            }
        },
        {
            field: 'reviewedDate',
            headerName: 'Reviewed Date',
            width: 160,
            renderCell: ({ row }) => <span>{format(new Date(row.reviewedDate), 'yyyy-MM-dd hh:mm a')}</span>
        },
        {
            field: 'reviewedBy',
            headerName: 'Reviewed By',
            width: 180,
            // renderCell: ({ row }) => <Tooltip title={row.reviewedBy}><span>{row.reviewedBy}</span></Tooltip>
        },
        {
            field: 'comment',
            headerName: 'Comments',
            width: 360,
            renderCell: ({ row }) => <Tooltip title={row.comment}><span>{row.comment}</span></Tooltip>
        },
    ];

    const orderHistoryCols = [
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: ({ row }) => {
                return [
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <CircleIcon
                            color={
                                row.status.toLowerCase() === 'open' ? 'primary'
                                : row.status.toLowerCase() === 'closed' ? 'success'
                                : row.status.toLowerCase() === 're-poen' ? 'warning'
                                : 'primary'
                            }
                            sx={{ mr: 1 }}
                        />
                        {row.status}
                    </Box>
                ]
            }
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 390,
            renderCell: ({ row }) => <span>{format(new Date(row.date), 'eeee, d MMMM yyyy, h:mm:ss a')}</span>
        }
    ];

    // ----- CONTEXTS
    const {
        userInfo,
        notifInfo,
        setNotifInfo,
        wipData,
        getWipWithoutValue,
        getWipWithValue,
        getWipClosed,
        applyFilters
    } = useAppContext()


    // ----- STATES
    const [wipInfo, setWipInfo] = useState(null);
    const [wipInfoProperties, setWipInfoProperties] = useState({status: '', escalationCutoff: null, reviewCutoff: null, orderAgeCutoff: null});
    const [wipInfoArray, setWipInfoArray] = useState([]);
    const [comments, setComments] = useState([]);
    const [saveCommentButtonEnabled, setSaveCommentButtonEnabled] = useState(true);

    // Comments
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const [commentActionType, setCommentActionType] = useState('view');
    const [selectedComment, setSelectedComment] = useState({
        id: '',
        comment: '',
        reviewedBy: userInfo.name,
        reviewedDate: ''
    });
    const [dialogOpenComment, setDialogOpenComment] = useState(false);
    // Order History
    const [isLoadingOrderHistory, setIsLoadingOrderHistory] = useState(false);
    const [orderHistory, setOrderHistory] = useState([]);
    const [dialogOpenOrderHistory, setDialogOpenOrderHistory] = useState(false);
    // Properties
    const [propertiesMode, setPropertiesMode] = useState('view');


    // ----- EFFECTS
    useEffect(() => {
        if (!row) return;
        setWipInfo(row);

        setWipInfoProperties({
            status: row.status,
            escalationCutoff: row.escalationCutoff,
            reviewCutoff: row.reviewCutoff,
            orderAgeCutoff: row.orderAgeCutoff
        })
    }, [row]);

    useEffect(() => {
        if (!wipInfo) return;

        setWipInfoArray([
            { label: 'WIP No', value: wipInfo.id },
            { label: 'Status', value: wipInfo.status },
            { label: 'Account', value: wipInfo.account },
            { label: 'Customer', value: wipInfo.customer },
            { label: 'Parts WIP', value: parseFloat(wipInfo.partsWip).toLocaleString('en') },
            { label: 'Reg No', value: wipInfo.regNo },
            { label: 'Labour WIP', value: parseFloat(wipInfo.labWip).toLocaleString('en') },
            { label: 'Sublet', value: parseFloat(wipInfo.sublet).toLocaleString('en') },
            { label: 'Total', value: parseFloat(wipInfo.total).toLocaleString('en') },
            { label: 'Age', value: wipInfo.age },
            { label: 'Date', value: format(new Date(wipInfo.date), 'eeee, d MMMM yyyy') },
            { label: 'Count', value: wipInfo.count },
            { label: 'Job No', value: wipInfo.jobNo },
            { label: 'Line', value: wipInfo.line },
            { label: 'Loc', value: wipInfo.loc },
            { label: 'Last Reviewed', value: wipInfo.reviewedDate ? format(new Date(wipInfo.reviewedDate), 'eeee, d MMMM yyyy, hh:mm a') : '' },
            { label: 'Last Reviewed By', value: wipInfo.reviewedBy }
        ]);
        
        getComments();

        // eslint-disable-next-line
    }, [wipInfo]);


    // ----- API FUNCTIONS
    const getComments = () => {
        setIsLoadingComments(true);

        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getCommentsByWip/?code=${key}&id=${wipInfo.id}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => new Date(b.reviewedDate) - new Date(a.reviewedDate))
            setComments(result);
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            });
        })
        .finally(() => {
            setIsLoadingComments(false);
        })
    }

    const getOrderHistory = () => {
        setIsLoadingOrderHistory(true);

        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getRepairOrderHistoryByWip/?code=${key}&id=${wipInfo.id}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => new Date(b.date) - new Date(a.date));
            setOrderHistory(result);
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            })
        })
        .then(() => {
            setIsLoadingOrderHistory(false);
        })
    }

    const addComment = (payload) => {
        const newReviewedDate = new Date();

        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }
        
        axios({
            method: 'post',
            url: `${url}/addCommentByWip/?code=${key}&id=${wipInfo.id}`,
            data: {
                username: payload.username,
                comment: payload.comment,
                reviewedDate: newReviewedDate,

            }
        })
        .then(() => {
            handleDialogCloseComment();
        })
        .then(() => {
            getComments();
        })
        .then(() => {
            updateCallback({
                ...row,
                comment: payload.comment,
                reviewedDate: format(newReviewedDate, `yyyy-MM-dd'T'hh:mm:ss.SSS'Z'`),
                reviewedBy: payload.username,
            });

            setWipInfo(p => {return {...p, comment: payload.comment}});
            setWipInfo(p => {return {...p, reviewedDate: format(newReviewedDate, `yyyy-MM-dd'T'hh:mm:ss.SSS'Z'`)}});
            setWipInfo(p => {return {...p, reviewedBy: payload.username}});
        })
        .then(() => {
            // getWipWithoutValue(wipData);
            // getWipWithValue(wipData);
            // getWipClosed(wipData);
            applyFilters();
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            })
        })
        .finally((res) => {
            setNotifInfo({
                open: true,
                severity: 'success',
                message: 'You comments have been recorded!'
            });

            setSaveCommentButtonEnabled(true);
        })
    }

    const updateWipProperties = (payload) => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'put',
            url: `${url}/updateWipProperties/?code=${key}&id=${wipInfo.id}`,
            data: { 
                status: payload.status,
                username: payload.username,
                escalationCutoff: payload.escalationCutoff,
                reviewCutoff: payload.reviewCutoff,
                orderAgeCutoff: payload.orderAgeCutoff
             }
        })
        .then((res) => {
            setNotifInfo({
                open: true,
                severity: 'success',
                message: `Properties of WIP ${wipInfo.id} has been updated!`
            });
        })
        .then(() => {
            updateCallback({
                ...row,
                status: payload.status,
                escalationCutoff: format(new Date(payload.escalationCutoff), `yyyy-MM-dd'T'00:00:00.000'Z'`),
                reviewCutoff: format(new Date(payload.reviewCutoff), `yyyy-MM-dd'T'00:00:00.000'Z'`),
                orderAgeCutoff: format(new Date(payload.orderAgeCutoff), `yyyy-MM-dd'T'00:00:00.000'Z'`)
            });

            row.status = payload.status;
            row.escalationCutoff = format(new Date(payload.escalationCutoff), `yyyy-MM-dd'T'00:00:00.000'Z'`);
            row.reviewCutoff = format(new Date(payload.reviewCutoff), `yyyy-MM-dd'T'00:00:00.000'Z'`);
            row.orderAgeCutoff = format(new Date(payload.orderAgeCutoff), `yyyy-MM-dd'T'00:00:00.000'Z'`);
        })
        .then(() => {
            // getWipWithoutValue(wipData);
            // getWipWithValue(wipData);
            // getWipClosed(wipData);
            applyFilters();
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            });
        })
    }


    // ----- FUNCTIONS
    // For closing this parent dialog
    const handleClose = () => {
        setWipInfo(null);
        onCloseCallback();
    }

    // Comments

    const handleViewAddCommentClick = (type, row) => {
        setCommentActionType(type);

        if (type === 'add') {
            setSelectedComment({
                id: wipInfo.id,
                comment: '',
                reviewedBy: userInfo.name,
                reviewedDate: ''
            })
        } else {
            setSelectedComment(row);
        }

        setDialogOpenComment(true);
    }

    const handleSaveCommentClick = () => {
        if (selectedComment.comment === '') {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: 'You did not enter any comment/notes!'
            });
            return;
        }

        setSaveCommentButtonEnabled(false);

        const payload = {
            comment: selectedComment.comment,
            username: userInfo.name
        }

        addComment(payload);
    }

    const handleDialogCloseComment = () => {
        setCommentActionType('view');
        setSelectedComment({
            id: '',
            comment: '',
            reviewedBy: userInfo.name,
            reviewedDate: ''
        })
        setDialogOpenComment(false);
    }

    // Review Order History

    const handleViewOrderHistoryClick = () => {
        getOrderHistory();
        setDialogOpenOrderHistory(true);
    }

    const handleDialogCloseOrderHistory = () => {
        setOrderHistory([]);
        setDialogOpenOrderHistory(false);
    }

    // Properties

    const handlePropertiesViewEditClick = (type) => {
        if (type === 'view') {
            const payload = {
                username: userInfo.name,
                status: wipInfoProperties.status,
                escalationCutoff: format(new Date(wipInfoProperties.escalationCutoff), 'yyyy-MM-dd'),
                orderAgeCutoff: format(new Date(wipInfoProperties.orderAgeCutoff), 'yyyy-MM-dd'),
                reviewCutoff: format(new Date(wipInfoProperties.reviewCutoff), 'yyyy-MM-dd'),
            }
            
            updateWipProperties(payload);
            setPropertiesMode('view');
            return;
        } else {
            setPropertiesMode('edit');
        }
    }

    const handlePropertiesDiscardClick = () => {

        setWipInfoProperties({
            status: row.status,
            escalationCutoff: row.escalationCutoff,
            reviewCutoff: row.reviewCutoff,
            orderAgeCutoff: row.orderAgeCutoff
        })

        setPropertiesMode('view');
    }

    
    // ----- RETURN
	return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <AppBar elevation={0} position='static'>
                    <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', bgcolor: 'black' }}>
                        <IconButton color='inherit' onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <IconButton color='inherit' onClick={handleViewOrderHistoryClick}>
                            <HistoryIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container maxWidth='xl' sx={{ height: '100%', overflow: 'hidden' }}>
                    <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                        <Grid container sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                            <Grid item xs={12} sm={5} sx={{ p: 2, pr: 1, height: '100%', width: '100%', overflow: 'hidden' }}>
                                <Paper elevation={3} sx={{ p: 2, height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Typography variant='h5'>Order Details</Typography>
                                    <Divider sx={{ mt: 1, mb: 2 }} />
                                    <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                                        {
                                            wipInfoArray.length > 0 &&
                                            wipInfoArray.map((r, index) => {
                                                return (
                                                    <Grid key={index} container sx={{ pb: 1, pt: 1, borderBottom: '1px solid #D7DBDD' }}>
                                                        <Grid item xs={12} sm={4} sx={{ pr: 2, textAlign: 'right' }}>
                                                            {r.label} :
                                                        </Grid>
                                                        <Grid item xs={12} sm={8}>
                                                            <strong>{r.value}</strong>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={7} sx={{ p: 2, pl: 1, height: '100%', width: '100%' }}>
                                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Paper elevation={3} sx={{ p: 2, }}>
                                        <Typography variant='h5'>Properties</Typography>
                                        <Divider sx={{ mt: 1, mb: 2 }} />
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel>Status</InputLabel>
                                                        <Select
                                                            disabled={propertiesMode === 'view'}
                                                            value={wipInfoProperties.status}
                                                            label="Status"
                                                            onChange={(e) => setWipInfoProperties(p => {return {...p, status: e.target.value}})}
                                                        >
                                                            <MenuItem value={`Reviewed`}>Reviewed</MenuItem>
                                                            <MenuItem value={`Not Yet Reviewed`}>Not Yet Reviewed</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DesktopDatePicker
                                                        label='Escalation Cutoff'
                                                        inputFormat='eeee, d MMMM yyyy'
                                                        disableMaskedInput
                                                        disabled={!(propertiesMode === 'edit' && (userInfo.admin))}
                                                        value={format(new Date(wipInfoProperties?.escalationCutoff), 'yyyy-MM-dd')}
                                                        onChange={(newValue) => setWipInfoProperties(p => {return {...p, escalationCutoff: newValue}})}
                                                        renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DesktopDatePicker
                                                        label='Order Age Cutoff'
                                                        inputFormat='eeee, d MMMM yyyy'
                                                        disableMaskedInput
                                                        disabled={!(propertiesMode === 'edit' && (userInfo.admin))}
                                                        value={format(new Date(wipInfoProperties?.orderAgeCutoff), 'yyyy-MM-dd')}
                                                        onChange={(newValue) => setWipInfoProperties(p => {return {...p, orderAgeCutoff: newValue}})}
                                                        renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DesktopDatePicker
                                                        label='Review Cutoff'
                                                        inputFormat='eeee, d MMMM yyyy'
                                                        disableMaskedInput
                                                        disabled={!(propertiesMode === 'edit' && (userInfo.admin))}
                                                        value={format(new Date(wipInfoProperties?.reviewCutoff), 'yyyy-MM-dd')}
                                                        onChange={(newValue) => setWipInfoProperties(p => {return {...p, reviewCutoff: newValue}})}
                                                        renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth size='small'
                                                        disabled label='Completed Date'
                                                        value={wipInfo?.completedDate ? format(new Date(wipInfo?.completedDate), 'eeee, d MMMM yyyy, hh:mm a') : 'n/a'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                        {
                                                            propertiesMode === 'edit' &&
                                                            <Button
                                                                variant='contained'
                                                                color='error'
                                                                startIcon={<DeleteIcon />}
                                                                onClick={handlePropertiesDiscardClick}
                                                                sx={{ mr: 2 }}
                                                            >
                                                                Discard
                                                            </Button>
                                                        }
                                                        <Button 
                                                            variant={propertiesMode === 'view' ? 'outlined' : 'contained'}
                                                            color='success'
                                                            startIcon={propertiesMode === 'view' ? <EditIcon /> : <SaveIcon />}
                                                            onClick={() => handlePropertiesViewEditClick(propertiesMode === 'view' ? 'edit' : 'view')}
                                                        >
                                                            {propertiesMode === 'view' ? 'Edit' : 'Save'}
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                    <Paper elevation={3} sx={{ mt: 2, p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                            <Typography variant='h5'>Reviews</Typography>
                                            <Button
                                                variant='contained'
                                                startIcon={<AddIcon />}
                                                onClick={() => handleViewAddCommentClick('add', null)}
                                            >
                                                Add Review
                                            </Button>
                                        </Box>
                                        <Divider sx={{ mt: 1, mb: 2 }} />
                                        <Box sx={{ flexGrow: 1, width: '100%' }}>
                                            <DataGrid
                                                rows={comments}
                                                columns={commentsCols}
                                                autoPageSize={true}
                                                loading={isLoadingComments}
                                                hideFooterSelectedRowCount
                                            />
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>


                {/* VIEW COMMENT DIALOG */}

                <Dialog
                    fullWidth
                    maxWidth='md'
                    open={dialogOpenComment}
                    onClose={handleDialogCloseComment}
                >
                    <DialogTitle>COMMENT FOR WIP {wipInfo?.id}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ p: 2 }}>
                            <TextField
                                type='text'
                                fullWidth
                                multiline
                                rows={6}
                                label='Comment'
                                placeholder='Comment'
                                disabled={commentActionType === 'view'}
                                value={selectedComment?.comment}
                                onChange={(e) => setSelectedComment(p => {return {...p, comment: e.target.value}})}
                            />
                            <TextField
                                type='text'
                                fullWidth
                                label='Reviewer'
                                placeholder='Reviewer'
                                disabled={true}
                                value={selectedComment?.reviewedBy}
                                sx={{ mt: 2 }}
                            />
                            <TextField
                                type='text'
                                fullWidth
                                label='Reviewed Date'
                                placeholder='Reviewed Date'
                                disabled={true}
                                value={
                                    commentActionType === 'add' ?
                                        'Recorded once saved...' 
                                    : selectedComment?.reviewedDate ?
                                        format(new Date(selectedComment?.reviewedDate), 'MMMM d, yyyy hh:mm a')
                                        : 'n/a'
                                }
                                sx={{ mt: 2 }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogCloseComment}>{commentActionType === 'view' ? 'Close' : 'Cancel'}</Button>
                        <Button
                            disabled={commentActionType === 'view' || !saveCommentButtonEnabled}
                            onClick={handleSaveCommentClick}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>


                {/* VIEW REPAIR ORDER HISTORY */}

                <Dialog
                    fullWidth
                    maxWidth='sm'
                    open={dialogOpenOrderHistory}
                    onClose={handleDialogCloseOrderHistory}
                >
                    <DialogTitle>REPAIR ORDER HISTORY FOR WIP {wipInfo?.id}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ height: 360 }}>
                            <DataGrid
                                rows={orderHistory}
                                columns={orderHistoryCols}
                                loading={isLoadingOrderHistory}
                                autoPageSize={true}
                                hideFooterSelectedRowCount
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogCloseOrderHistory}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>


                <Notif
                    open={notifInfo.open}
                    severity={notifInfo.severity}
                    message={notifInfo.message}
                    onCloseCallback={setNotifInfo}
                />
            </Dialog>
        </LocalizationProvider>
	);
};

export default WipView;
