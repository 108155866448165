import React, { useState, useEffect } from 'react';
import axios from 'axios';


import { 
    Box, 
    Button, 
    Checkbox, 
    Dialog,
    DialogActions, 
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip 
} from '@mui/material';
import { useAppContext } from '../../contexts/appContext';
import { DataGrid } from '@mui/x-data-grid';
import {
	Edit as EditIcon,
	DeleteForever as DeleteIcon,
	AddCircleOutline as PlusCircleIcon,
    ConstructionOutlined,
} from '@mui/icons-material';


import Notif from '../Notif';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};


const Users = () => {
    const cols = [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            width: 60
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 220,
            renderCell: ({ row }) => <Tooltip title={row.name}><span>{row.name}</span></Tooltip>
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 260,
            renderCell: ({ row }) => <Tooltip title={row.email}><span>{row.email}</span></Tooltip>
        },
        {
            field: 'region',
            headerName: 'Regions',
            width: 100,
            renderCell: ({ row }) => <Tooltip title={row.region}><span>{row.region}</span></Tooltip>
        },
        {
            field: 'market',
            headerName: 'Markets',
            width: 100,
            renderCell: ({ row }) => <Tooltip title={row.market}><span>{row.market}</span></Tooltip>
        },
        {
            field: 'department',
            headerName: 'Departments',
            width: 180,
            renderCell: ({ row }) => <Tooltip title={row.department}><span>{row.department?.substring(0, 20)}{row.department.length > 20 ? '...' : ''}</span></Tooltip>
        },
        {
            field: 'dealership',
            headerName: 'Dealerships',
            width: 180,
            renderCell: ({ row }) => <Tooltip title={row.dealership}><span>{row.dealership?.substring(0, 20)}{row.dealership.length > 20 ? '...' : ''}</span></Tooltip>
        },
        {
            field: 'reviewer',
            headerName: 'Reviewer',
            width: 90,
            type: 'boolean'
        },
        {
            field: 'escalation1',
            headerName: 'Escalation',
            width: 90,
            type: 'boolean'
        },
        {
            field: 'admin',
            headerName: 'Admin',
            width: 90,
            type: 'boolean'
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: ({ row }) => {
                return [
                    <IconButton onClick={() => handleAddEditClick('edit', row)}>
                        <EditIcon />
                    </IconButton>,
                    <IconButton onClick={() => handleDialogOpenDelete(row)}>
                        <DeleteIcon />
                    </IconButton>
                ]
            }
        }
    ]


    // ----- CONTEXTS
    const {
        userInfo,
        userList, setUserList,
        notifInfo, setNotifInfo,
        entityHierarchyList,
        regionOptions,
        marketOptions,
        dealershipOptions,
        departmentOptions,
    } = useAppContext();


    // ----- STATES
    const [filteredUsers, setFilteredUsers] = useState([])
    const [searchText, setSearchText] = useState('');
    const [checkboxFilters, setCheckboxFilters] = useState({reviewer: false, escalation1: false, admin: false})
    const [isLoading, setIsLoading] = useState(false);
    const [addEditType, setAddEditType] = useState('add');
    const [userFormData, setUserFormData] = useState({
        id: '',
        name: '',
        email: '',
        region: [],
        market: [],
        department: [],
        dealership: [],
        reviewer: false,
        escalation1: false,
        admin: false,
        username: userInfo.name
    });
    const [dialogOpenAddEdit, setDialogOpenAddEdit] = useState(false);
    const [dialogOpenDelete, setDialogOpenDelete] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [filteredRegions, setFilteredRegions] = useState([]);
    const [filteredMarkets, setFilteredMarkets] = useState([]);
    const [filteredDealerships, setFilteredDealerships] = useState([]);
    const [filteredDepartments, setFilteredDepartments] = useState([]);


    // ----- EFFECTS
    useEffect(() => {
        if (userList.length <= 0) getUsers();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userList.length <= 0) return;
        setFilteredUsers(userList);
    }, [userList]);

    useEffect(() => {
        setFilteredRegions(regionOptions);
        setFilteredMarkets(marketOptions);
        setFilteredDealerships(dealershipOptions);
        setFilteredDepartments(departmentOptions);
    }, [marketOptions, dealershipOptions, departmentOptions]);

    useEffect(() => {
        applyFilters();

        // eslint-disable-next-line
    }, [searchText, checkboxFilters]);


    // ----- API FUNCTIONS
    const getUsers = () => {
        setIsLoading(true);

        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getUsers/?code=${key}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => a.name.localeCompare(b.name));
            setUserList(result);
            setFilteredUsers(result);
        })
        .then(() => {
            setIsLoading(false);
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            })
        })
    }

    const addUser = (payload) => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'post',
            url: `${url}/addUser/?code=${key}`,
            data: {
                name: payload.name,
                email: payload.email,
                region: payload.region,
                market: payload.market,
                department: payload.department,
                dealership: payload.dealership,
                reviewer: payload.reviewer,
                escalation1: payload.escalation1,
                escalation2: payload.escalation2,
                admin: payload.admin,
                username: payload.name
            }
        })
        .then((res) => {
            setNotifInfo({
                open: true,
                severity: 'success',
                message: `User was successfully added!`
            });
        })
        .then(() => {
            getUsers();
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            });
        })
    }

    const updateUser = (payload) => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'put',
            url: `${url}/updateUser/?code=${key}&id=${payload.id}`,
            data: {
                name: payload.name,
                email: payload.email,
                region: payload.region,
                market: payload.market,
                department: payload.department,
                dealership: payload.dealership,
                reviewer: payload.reviewer,
                escalation1: payload.escalation1,
                escalation2: payload.escalation2,
                admin: payload.admin,
                username: payload.name
            }
        })
        .then((res) => {
            setNotifInfo({
                open: true,
                severity: 'success',
                message: `User was successfully updated!`
            });
        })
        .then(() => {
            getUsers();
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            });
        })
    }

    const deleteUser = (id) => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'delete',
            url: `${url}/deleteUser/?code=${key}&id=${id}`
        })
        .then(() => {
            getUsers();
        })
        .then((res) => {
            setNotifInfo({
                open: true,
                severity: 'success',
                message: `User was successfully deleted!`
            });
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            });
        })
    }


    // ----- FUNCTIONS

    // Add/Edit User
    const handleAddEditClick = (type, row) => {
        setAddEditType(type)

        if (type === 'add') {
            setUserFormData({
                id: '',
                name: '',
                email: '',
                region: [],
                market: [],
                department: [],
                dealership: [],
                reviewer: false,
                escalation1: false,
                admin: false,
                username: userInfo.name
            })
        } else {
            setUserFormData({
                id: row.id,
                name: row.name,
                email: row.email,
                region: row.region.split(';'),
                market: row.market.split(';'),
                department: row.department.split(';'),
                dealership: row.dealership.split(';'),
                reviewer: row.reviewer,
                escalation1: row.escalation1,
                admin: row.admin,
                username: userInfo.name
            });
        }

        setDialogOpenAddEdit(true);
    }

    const handleAddEditSaveConfirm = () => {
        if (userFormData.email === '' || userFormData.name === '' ||
        userFormData.region.length <= 0 || userFormData.market.length <= 0 ||
        userFormData.dealership.length <= 0 || userFormData.department.length <= 0 ||
        (!userFormData.reviewer && !userFormData.escalation1 && !userFormData.admin)) {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: 'Error! You missed some important field(s)!'
            });
            return;
        }

        let region = userFormData.region.join(';');
        region = region.substring(0,1) == ";" ? region.substring(1, region.length) : region

        let market = userFormData.market.join(';');
        market = market.substring(0,1) == ";" ? market.substring(1, market.length) : market

        let dealership = userFormData.dealership.join(';');
        dealership = dealership.substring(0,1) == ";" ? dealership.substring(1, dealership.length) : dealership

        let department = userFormData.department.join(';');
        department = department.substring(0,1) == ";" ? department.substring(1, department.length) : department

        const payload = {
            id: userFormData.id,
            name: userFormData.name,
            email: userFormData.email,
            region: region,
            market: market,
            department: department,
            dealership: dealership,
            reviewer: userFormData.reviewer ? 1 : 0,
            escalation1: userFormData.escalation1 ? 1 : 0,
            escalation2: 0,
            admin: userFormData.admin ? 1 : 0,
            username: userInfo.name
        }

        if (addEditType === 'add') {
            addUser(payload);
        } else {
            updateUser(payload);
        }

        handleDialogCloseAddEdit();
    }

    const handleDialogCloseAddEdit = () => {
        resetUserFormData();
        setDialogOpenAddEdit(false);
    }

    const resetUserFormData = () => {
        setUserFormData({
            id: '',
            name: '',
            email: '',
            region: [],
            market: [],
            department: [],
            dealership: [],
            reviewer: false,
            escalation1: false,
            admin: false,
            username: userInfo.name
        })
    }

    // Delete user
    const handleDialogOpenDelete = (row) => {
        setSelectedRow(row);
        setDialogOpenDelete(true);
    }

    const handleConfirmDeleteUser = () => {
        if (!selectedRow) return;
        deleteUser(selectedRow.id);
        handleDialogClose();
    }

    const handleDialogClose = () => {
        setSelectedRow(null);
        setDialogOpenDelete(false);
    }

    const applyFilters = () => {
        let newList = userList;

        if (searchText === '') {
            setFilteredUsers(userList)
        } else {
            newList = userList.filter(r => r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || 
                r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        }
        
        if (!checkboxFilters.reviewer && !checkboxFilters.escalation1 && !checkboxFilters.admin) {
        } else {
            newList = newList.filter(r => r.reviewer === checkboxFilters.reviewer || r.escalation1 === checkboxFilters.escalation1 || r.admin === checkboxFilters.admin)
        }
            
        setFilteredUsers(newList);
    }

    // Dropdown changes
    const unique = (value, index, self) => {
        return self.indexOf(value) === index
    }

    const handleSelectedRegionChange = (event) => {
        const { target: { value } } = event;
        setUserFormData(p => {
            return {
                ...p,
                region: typeof value === 'string' ? value.split(';') : value,
                market: [],
                dealership: [],
                department: []
            }
        });

        if (value.length <= 0) {
            setFilteredMarkets(marketOptions);
            setFilteredDealerships(dealershipOptions);
            setFilteredDepartments(departmentOptions);
        } else {
            setFilteredMarkets([]);
            setFilteredDealerships([]);
            setFilteredDepartments([]);
        }

        const selectedRegions = typeof value === 'string' ? value.split(';') : value;
        let optionList = entityHierarchyList.filter(r => selectedRegions.includes(r.region));
        optionList = optionList.map(r => r.market).filter(unique);
        optionList = optionList.map(r => {return {key: r, value: r, label: r}});
        setFilteredMarkets(optionList);
    };

    const handleSelectedMarketChange = (event) => {
        const { target: { value } } = event;
        setUserFormData(p => {
            return {
                ...p,
                market: typeof value === 'string' ? value.split(';') : value,
                dealership: [],
                department: []
            }
        });

        if (value.length <= 0) {
            setFilteredDealerships(dealershipOptions);
            setFilteredDepartments(departmentOptions);
        } else {
            setFilteredDealerships([]);
            setFilteredDepartments([]);
        }

        const selectedRegions = userFormData.region;
        const selectedMarkets = typeof value === 'string' ? value.split(',') : value;
        let optionList = entityHierarchyList.filter(r => selectedRegions.includes(r.region) &&
            selectedMarkets.includes(r.market));
        console.log(optionList);
        optionList = optionList.map(r => r.dealership).filter(unique);
        optionList = optionList.map(r => {return {key: r, value: r, label: r}});
        setFilteredDealerships(optionList);
    };

    const handleSelectedDealershipChange = (event) => {
        const { target: { value } } = event;
        
        setUserFormData(p => {
            return {
                ...p,
                dealership: typeof value === 'string' ? value.split(';') : value,
                // department: []
            }
        });

        if (value.length <= 0) {
            setFilteredDepartments(departmentOptions);
        } else {
            setFilteredDepartments([]);
            setUserFormData(p => {return {...p, department: []}})
        }

        const selectedRegions = userFormData.region;
        const selectedMarkets = userFormData.market;
        const selectedDealerships = typeof value === 'string' ? value.split(';') : value;
        let optionList = entityHierarchyList.filter(r => selectedRegions.includes(r.region) &&
            selectedMarkets.includes(r.market) &&
            selectedDealerships.includes(r.dealership));
        optionList = optionList.map(r => r.department).filter(unique);
        optionList = optionList.map(r => {return {key: r, value: r, label: r}});
        setFilteredDepartments(optionList);
    };

    const handleSelectedDepartmentChange = (event) => {
        const { target: { value } } = event;
        setUserFormData(p => {return {...p, department: typeof value === 'string' ? value.split(';') : value }});
    };


    // ----- RETURN
	return (
		<Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                
                {/* FILTERS */}

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <TextField
                        type='text'
                        label='Search'
                        size='small'
                        placeholder='Search name or email...'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        sx={{
                            width:  300
                        }}
                    />

                    <FormGroup sx={{ ml: 3 }}>
                        <FormControlLabel
                            label="Reviewer"
                            control={
                                <Checkbox
                                    checked={checkboxFilters.reviewer}
                                    onChange={(e) => setCheckboxFilters(p => {return {...p, reviewer: e.target.checked}})}
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup sx={{ ml: 1 }}>
                        <FormControlLabel
                            label="Escalation"
                            control={
                                <Checkbox
                                    checked={checkboxFilters.escalation1}
                                    onChange={(e) => setCheckboxFilters(p => {return {...p, escalation1: e.target.checked}})}
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup sx={{ ml: 1 }}>
                        <FormControlLabel
                            label="Admin"
                            control={
                                <Checkbox
                                    checked={checkboxFilters.admin}
                                    onChange={(e) => setCheckboxFilters(p => {return {...p, admin: e.target.checked}})}
                                />
                            }
                        />
                    </FormGroup>
                </Box>
                <Button
                    variant='contained'
                    startIcon={<PlusCircleIcon />}
                    onClick={() => handleAddEditClick('add')}
                >
                    Add User
                </Button>
            </Box>

            
            {/* USER TABLE */}

            <Box sx={{ mt: 2, flexGrow: 1, width: '100%' }}>
                <DataGrid
                    rows={filteredUsers}
                    columns={cols}
                    loading={isLoading}
                    autoPageSize={true}
                />
            </Box>


            {/* ADD EDIT USER DIALOG */}

            <Dialog
                fullWidth
                maxWidth='md'
                open={dialogOpenAddEdit}
                onClose={handleDialogCloseAddEdit}
            >
                <DialogTitle>{addEditType === 'add' ? 'ADD' : 'MODIFY'} USER</DialogTitle>
                <DialogContent>
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label='Email'
                                    placeholder='Email'
                                    value={userFormData.email}
                                    onChange={(e) => setUserFormData(p => {return {...p, email: e.target.value}})}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label='Name'
                                    placeholder='Name'
                                    value={userFormData.name}
                                    onChange={(e) => setUserFormData(p => {return {...p, name: e.target.value}})}
                                />
                            </Grid>

                            {/* REGIONS */}

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth sx={{  }}>
                                    <InputLabel>Regions</InputLabel>
                                    <Select
                                        multiple
                                        value={userFormData.region}
                                        onChange={handleSelectedRegionChange}
                                        input={<OutlinedInput label="Regions" />}
                                        renderValue={(selected) => selected.join(';')}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            filteredRegions.map((name) => (
                                                <MenuItem key={name.key} value={name.value}>
                                                    <Checkbox checked={userFormData.region.indexOf(name.label) > -1} />
                                                    <ListItemText primary={name.label} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* MARKETS */}

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth sx={{  }}>
                                    <InputLabel>Markets</InputLabel>
                                    <Select
                                        multiple
                                        value={userFormData.market}
                                        onChange={handleSelectedMarketChange}
                                        input={<OutlinedInput label="Markets" />}
                                        renderValue={(selected) => selected.join(';')}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            filteredMarkets.map((name) => (
                                                <MenuItem key={name.key} value={name.value}>
                                                    <Checkbox checked={userFormData.market.indexOf(name.label) > -1} />
                                                    <ListItemText primary={name.label} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* DEALERSHIPS */}

                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth sx={{  }}>
                                    <InputLabel>Dealerships</InputLabel>
                                    <Select
                                        multiple
                                        value={userFormData.dealership}
                                        onChange={handleSelectedDealershipChange}
                                        input={<OutlinedInput label="Dealerships" />}
                                        renderValue={(selected) => selected.join(';')}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            filteredDealerships.map((name) => (
                                                <MenuItem key={name.key} value={name.value}>
                                                    <Checkbox checked={userFormData.dealership.indexOf(name.label) > -1} />
                                                    <ListItemText primary={name.label} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* DEPARTMENTS */}

                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth sx={{  }}>
                                    <InputLabel>Departments</InputLabel>
                                    <Select
                                        multiple
                                        value={userFormData.department}
                                        onChange={handleSelectedDepartmentChange}
                                        input={<OutlinedInput label="Departments" />}
                                        renderValue={(selected) => selected.join(';')}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            filteredDepartments.map((name) => (
                                                <MenuItem key={name.key} value={name.value}>
                                                    <Checkbox checked={userFormData.department.indexOf(name.label) > -1} />
                                                    <ListItemText primary={name.label} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        label="Reviewer"
                                        control={
                                            <Checkbox
                                                checked={userFormData.reviewer}
                                                onChange={(e) => setUserFormData(p => {return {...p, reviewer: e.target.checked}})}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        label="Escalation"
                                        control={<Checkbox
                                            checked={userFormData.escalation1}
                                            onChange={(e) => setUserFormData(p => {return {...p, escalation1: e.target.checked}})} />
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        label="Admin"
                                        control={<Checkbox
                                            checked={userFormData.admin}
                                            onChange={(e) => setUserFormData(p => {return {...p, admin: e.target.checked}})} />
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCloseAddEdit}>Cancel</Button>
                    <Button onClick={handleAddEditSaveConfirm}>Save</Button>
                </DialogActions>
            </Dialog>


            {/* DELETE USER DIALOG */}

            <Dialog
                fullWidth
                maxWidth='sm'
                open={dialogOpenDelete}
                onClose={handleDialogClose}
            >
                <DialogTitle>DELETE USER</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete {selectedRow?.name} from the user list?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button color='error' onClick={handleConfirmDeleteUser}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Notif
                open={notifInfo.open}
                severity={notifInfo.severity}
                message={notifInfo.message}
                onCloseCallback={setNotifInfo}
            />
		</Box>
	);
};

export default Users;
