import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAppContext } from '../../contexts/appContext';


import SmallTableContainer from '../SmallTableContainer';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import {
	Edit as EditIcon,
	DeleteForever as DeleteIcon,
	AddCircleOutline as PlusCircleIcon,
} from '@mui/icons-material';


import Notif from '../Notif';
import { Box } from '@mui/system';


const Markets = () => {
	const dataName = 'MARKET'
	const cols = [
		{
			field: 'id',
			headerName: 'ID',
			type: 'number',
			width: 60
		},
		{
			field: 'name',
			headerName: 'Name',
			type: 'string',
			width: 640
		},
		{
			field: 'actions',
			type: 'actions',
			getActions: ({ row }) => {
				return [
					<IconButton onClick={() => handleAddEditClick('edit', row)}>
						<EditIcon />
					</IconButton>,
					<IconButton onClick={() => handleDeleteClick(row)}>
						<DeleteIcon />
					</IconButton>
				]
			}
		}
	];


	// ----- CONTEXTS
	const {
		userInfo,
		marketList, setMarketList,
		notifInfo, setNotifInfo
	} = useAppContext();


	// ----- STATES
	const [isLoading, setIsLoading] = useState(false);
	const [selectedRow, setSelectedRow] = useState({type: '', name: ''});
	const [dialogOpenAddEdit, setDialogOpenAddEdit] = useState(false);
	const [dialogOpenDelete, setDialogOpenDelete] = useState(false);


	// ----- EFFECTS
	useEffect(() => {
		if (marketList.length <= 0) getData();
		
		// eslint-disable-next-line
	}, []);


	// ----- API FUNCTIONS
	const getData = () => {
		setIsLoading(true);

		let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

		axios({
			method: 'get',
			url: `${url}/getMarkets/?code=${key}`
		})
		.then((res) => {
			let result = res.data.data.recordset;
			result = result.sort((a, b) => a.name.localeCompare(b.name))
			setMarketList(result);
		})
		.then(() => {
			setIsLoading(false);
		})
		.catch((error) => {
			console.log(error);
		})
	}

	const addData = (payload) => {
		let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

		axios({
			method: 'post',
			url: `${url}/addMarket/?code=${key}`,
			data: {
				username: userInfo.name,
				name: payload.name
			}
		})
		.then((res) => {
			setNotifInfo({
				open: true,
				severity: 'success',
				message: `New ${dataName} was successfully added!`
			});
		})
		.then(() => {
			getData();
		})
		.catch((error) => {
			setNotifInfo({
				open: true,
				severity: 'error',
				message: `Error! ${error.message}`
			});
		})
	}

	const updateData = (payload) => {
		let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

		axios({
			method: 'put',
			url: `${url}/updateMarket/?code=${key}&id=${payload.id}`,
			data: {
				username: userInfo.name,
				name: payload.name
			}
		})
		.then((res) => {
			setNotifInfo({
				open: true,
				severity: 'success',
				message: `${dataName} was successfully updated to ${payload.name}!`
			});
		})
		.then(() => {
			getData();
		})
		.catch((error) => {
			setNotifInfo({
				open: true,
				severity: 'error',
				message: `Error! ${error.message}`
			});
		})
	}

	const deleteData = (payload) => {
		let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

		axios({
			method: 'delete',
			url: `${url}/deleteMarket/?code=${key}&id=${payload.id}`
		})
		.then((res) => {
			setNotifInfo({
				open: true,
				severity: 'success',
				message: `${payload.name} was successfully deleted!`
			});
		})
		.then(() => {
			getData();
		})
		.catch((error) => {
			setNotifInfo({
				open: true,
				severity: 'error',
				message: `Error! ${error.message}`
			});
		})
	}


	// ----- FUNCTIONS
	const handleAddEditClick = (type, row) => {
		if (type === 'add') {
			setSelectedRow({type: type, name: ''});
		} else {
			setSelectedRow({type: type, ...row});
		}

		setDialogOpenAddEdit(true);
	}

	const handleAddEditSaveClick = () => {
		if (selectedRow.name === '') return;

		if (selectedRow.type === 'add') {
			addData({ name: selectedRow.name });
		} else {
			updateData({ id: selectedRow.id, name: selectedRow.name });
		}

		setSelectedRow({type: '', name: ''});
		setDialogOpenAddEdit(false);
	}

	const handleDialogCloseAddEdit = () => {
		setSelectedRow({type: '', name: ''});
		setDialogOpenAddEdit(false);
	}

	const handleDeleteClick = (row) => {
		setSelectedRow({type: 'delete', ...row});
		setDialogOpenDelete(true);
	}

	const handleDialogCloseDelete = () => {
		setSelectedRow({type: '', name: ''});
		setDialogOpenDelete(false);
	}

	const handleDialogConfirmDelete = () => {
		if (!selectedRow.id || selectedRow.id === '') return;

		deleteData({ id: selectedRow.id, name: selectedRow.name });

		setSelectedRow({type: '', name: ''});
		setDialogOpenDelete(false);
	}

	
	return (
		<SmallTableContainer>
			<Button
				variant='contained'
				startIcon={<PlusCircleIcon />}
				onClick={() => handleAddEditClick('add')}
				sx={{ mb: 2 }}
			>
				ADD NEW {dataName}
			</Button>

			<DataGrid
				rows={marketList}
				columns={cols}
				loading={isLoading}
				autoPageSize={true}
			/>

			
			{/* ADD/EDIT DIALOG */}

			<Dialog
				fullWidth
				maxWidth='sm'
				open={dialogOpenAddEdit}
				onClose={handleDialogCloseAddEdit}
			>
				<DialogTitle>{selectedRow?.type === 'add' ? `ADD NEW ${dataName}` : `MODIFY ${dataName}`}</DialogTitle>
				<DialogContent>
					<Box sx={{ p: 2}}>
						<TextField
							type='text'
							fullWidth
							label={dataName}
							placeholder={dataName}
							value={selectedRow?.name}
							onChange={(e) => setSelectedRow(p => {return {...p, name: e.target.value}})}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogCloseAddEdit}>Cancel</Button>
					<Button onClick={handleAddEditSaveClick}>Save</Button>
				</DialogActions>
			</Dialog>


			{/* DELETE DIALOG */}

			<Dialog
				fullWidth
				maxWidth='sm'
				open={dialogOpenDelete}
				onClose={handleDialogCloseAddEdit}
			>
				<DialogTitle>DELETE {dataName}</DialogTitle>
				<DialogContent>
					Are you sure you want to delete {selectedRow.name}?
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogCloseDelete}>Cancel</Button>
					<Button color='error' onClick={handleDialogConfirmDelete}>Confirm</Button>
				</DialogActions>
			</Dialog>

			<Notif
				open={notifInfo.open}
				severity={notifInfo.severity}
				message={notifInfo.message}
				onCloseCallback={setNotifInfo}
			/>
		</SmallTableContainer>
	);
};

export default Markets;
