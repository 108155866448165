import { Paper } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';

const SmallTableContainer = ({ children }) => {
	return (
        <Container maxWidth='md' sx={{ height: '100%' }}>
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                }}
            >
                {children}
            </Paper>
        </Container>
	);
};

export default SmallTableContainer;
