import React, { useState, useEffect } from 'react';
import { format } from 'date-fns'
import axios from 'axios';


import { Box } from '@mui/system';
import {
	OutlinedInput,
	InputLabel,
	MenuItem,
	FormControl,
	ListItemText,
	Select,
	Checkbox,
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from '@mui/material';
import {
    Refresh as RefreshIcon,
    EventBusyOutlined as CalendarXIcon,
    EventAvailableOutlined as CalendarCheckIcon,
    FilterAltOff as FilterOffIcon
} from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


import { useAppContext } from '../contexts/appContext';
import Notif from './Notif';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 300,
		}
	}
};

const Filters = () => {
    // ----- CONTEXTS
    const {
        userInfo,
        notifInfo,
        setNotifInfo,
        userFilters,
        searchText,
        setSearchText,
        setUserFilters,
        entityHierarchyList,
        userRegionOptions,
        userMarketOptions,
        userDepartmentOptions,
        userDealershipOptions,
        wipData,
        setIsLoadingWipData,
        setIsLoadingNotif,
        setWipData,
        applyFilters,
        setWipWithoutValue,
        setWipWithValue,
        setWipClosed,
    } = useAppContext();


    // ----- STATES
    const [dialogOpenDateFilters, setDialogOpenDateFilters] = useState(false);
    const [userRegionFilteredOptions, setUserRegionFilteredOptions] = useState([]);
    const [userMarketFilteredOptions, setUserMarketFilteredOptions] = useState([]);
    const [userDealershipFilteredOptions, setUserDealershipFilteredOptions] = useState([]);
    const [userDepartmentFilteredOptions, setUserDepartmentFilteredOptions] = useState([]);

    const [userRegions, setUserRegions] = useState([]);
    const [userMarkets, setUserMarkets] = useState([]);
    const [userDealerships, setUserDealerships] = useState([]);
    const [userDepartments, setUserDepartments] = useState([]);


    // ----- EFFECTS
    useEffect(() => {
        if (wipData.length <= 0) return;
        
        applyFilters();

        // eslint-disable-next-line
    }, [searchText]);

    useEffect(() => {
        applyFilters();

        // eslint-disable-next-line
    }, [userFilters]);

    useEffect(() => {
        setUserRegions(userRegionOptions.map(r => r.value).filter(unique));
        setUserMarkets(userMarketOptions.map(r => r.value).filter(unique));
        setUserDealerships(userDealershipOptions.map(r => r.value).filter(unique));
        setUserDepartments(userDepartmentOptions.map(r => r.value).filter(unique));
        setUserRegionFilteredOptions(userRegionOptions);
        setUserMarketFilteredOptions(userMarketOptions);
        setUserDealershipFilteredOptions(userDealershipOptions);
        setUserDepartmentFilteredOptions(userDepartmentOptions);
    }, [userRegionOptions, userMarketOptions, userDealershipOptions, userDepartmentOptions]);


    // ----- FIREBASE FUNCTIONS

    const getWipDataFiltered1 = (payload) => {
            setIsLoadingWipData(true);
            let result = [];
            let url = '';
            let key = '';
            let rowCount = 0;
            const pageLimit = 1000;
    
            if (window.location.hostname === 'localhost') {
                url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
                key = process.env.REACT_APP_API_KEY_DEV;
            } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
                url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
                key = process.env.REACT_APP_API_KEY_DEV;
            } else {
                url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
                key = process.env.REACT_APP_API_KEY_PROD;
            }
            
            axios({
                method: 'post',
                url: `${url}/getWipByEmailFiltered`,
                params: {
                    code: key,
                    email: userInfo.email
                },
                data: payload,
            })
            .then(res => {
                result = res.data.data.recordset;
                setWipData(result);
            })
            .then(() => {
                    setWipWithoutValue(wipData.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0 && r.status.toLowerCase() !== 'closed'));
                    setWipWithValue(wipData.filter(r => (r.partsWip > 0 || r.labWip > 0 || r.sublet > 0) && r.status.toLowerCase() !== 'closed'));
                    setWipClosed(wipData.filter(r => r.status.toLowerCase() === 'compelted' || r.status.toLowerCase() === 'closed'));
            })
            .catch((err) => {
                setNotifInfo({
                    open: true,
                    severity: 'error',
                    message: `Error: ${err.message}`
                });
            })
            .finally(() => {
                setIsLoadingWipData(false);
            });
        }

    const getWipDataFiltered = (payload, cb) => {
        setIsLoadingWipData(true);
        setIsLoadingNotif(true);

        let result = [];
        let resultAll = [];
        let url = '';
        let key = '';
        let rowCount = 0;
        const pageLimit = 10000;

        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'post',
            url: `${url}/getWipByEmailCount`,
            params: {
                code: key,
                email: userInfo.email
            },
            data: payload,
        })
        .then(res => {
            rowCount = res.data.data.recordset[0].count;
        })
        .then(() => {
            setWipData([]);
        })
        .then(() => {
            // IF COUNT IS LESS THAN LIMIT
            if (rowCount <= pageLimit) {
                axios({
                    method: 'post',
                    url: `${url}/getWipByEmailFiltered`,
                    params: {
                        code: key,
                        email: userInfo.email
                    },
                    data: payload,
                })
                .then(res => {
                    result = res.data.data.recordset;
                    setWipData(result);
                })
                .then(() => {
                        setWipWithoutValue(wipData.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0 && r.status.toLowerCase() !== 'closed'));
                        setWipWithValue(wipData.filter(r => (r.partsWip > 0 || r.labWip > 0 || r.sublet > 0) && r.status.toLowerCase() !== 'closed'));
                        setWipClosed(wipData.filter(r => r.status.toLowerCase() === 'compelted' || r.status.toLowerCase() === 'closed'));
                })
                .then(() => {
                    setIsLoadingWipData(false);
                    setIsLoadingNotif(false);
                });

            // IF COUNT IS MORE THAN LIMIT
            } else {
                for (let i=0; i<= rowCount; i+=pageLimit) {
                    axios({
                        method: 'post',
                        url: `${url}/getWipByEmailPaginated`,
                        params: {
                            code: key,
                            email: userInfo.email,
                            offset: i,
                            limit: pageLimit
                        },
                        data: payload,
                    })
                    .then(res => {
                        result = res.data.data.recordset;
                        resultAll = resultAll.concat(result);
                    })
                    .then(() => {
                        setWipData(p => {
                            return p.concat(result);
                        });
                    })
                    .then(() => {
                            setWipWithoutValue(wipData.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0 && r.status.toLowerCase() !== 'closed'));
                            setWipWithValue(wipData.filter(r => (r.partsWip > 0 || r.labWip > 0 || r.sublet > 0) && r.status.toLowerCase() !== 'closed'));
                            setWipClosed(wipData.filter(r => r.status.toLowerCase() === 'compelted' || r.status.toLowerCase() === 'closed'));
                    })
                    .then(() => {
                        if (i == 0) {
                            setIsLoadingWipData(false);
                            setNotifInfo({
                                open: true,
                                severity: 'warning',
                                message: `Data will be loaded incrementally`
                            });
                        };

                        if (resultAll.length >= rowCount) {
                            setIsLoadingNotif(false);
                        };
                    });
                }
            }
            
        })
        .then(() => {
            resetFilters();
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error}`
            });
        });
    }


    // ----- FUNCTIONS
    const unique = (value, index, self) => {
        return self.indexOf(value) === index
    }

    const handledLoadDataClick = () => {
        const region = userFilters.region.length == 0 ? 'None' : userFilters.region[0];
        const market = userFilters.market.length == 0 ? 'None' : userFilters.market[0];
        const dealerships = userFilters.dealership.length == 0 ? 'All' : userFilters.dealership.join (';');
        const departments = userFilters.department.length == 0 ? 'All' : userFilters.department.join (';');

        if (region == 'None' || market == 'None' || region == '' || market == '') {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Please select a Region and a Market.`
            });
            return;
        };

        getWipDataFiltered({
            region: region,
            market: market,
            dealerships: dealerships,
            departments: departments 
        });
        
    }

    const resetFilters = () => {
        setUserFilters(p => {
            return {
                ...p,
                dateFiltered: false,
                dateField: '',
                dateFrom: new Date(),
                dateTo: new Date()
            }
        })
        setSearchText('');
        setWipWithoutValue(wipData.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0));
        setWipWithValue(wipData.filter(r => r.partsWip > 0 || r.labWip > 0 || r.sublet > 0));
        setWipClosed(wipData.filter(r => r.status === 'Completed' || r.status === 'Closed'));
    }

    const handleDateFilterApplyClick = async (callback) => {
        if (userFilters.dateField === '') {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: 'Please select a date field!'
            })
            return;
        }

        await setUserFilters(p => {
            return {
                ...p,
                dateFiltered: true,
            }
        });

        setDialogOpenDateFilters(false);
    }

    const handleDialogResetDateFilters = () => {
        setUserFilters(p => {
            return {
                ...p,
                dateFiltered: false,
                dateField: '',
                dateFrom: new Date(), 
                dateTo: new Date(),
            }
        });
    }


    // Filter changes
	const handleUserRegionChange = (event) => {
		const { target: { value } } = event;
		// setUserFilters(p => {return {...p, region: typeof value === 'string' ? value.split(',') : value,}});

        setUserFilters(p => {
            return {
                ...p,
                region: [value],
                market: [],
                dealership: [],
                department: [],
            }
        });

        if (value.length <=0 ) {
            setUserMarketFilteredOptions(userMarketOptions);
            setUserDealershipFilteredOptions(userDealershipOptions);
            setUserDepartmentFilteredOptions(userDepartmentOptions);
        } else {
            setUserMarketFilteredOptions([]);
            setUserDealershipFilteredOptions([]);
            setUserDepartmentFilteredOptions([]);

            // Generate Market Options
            const selectedRegions = typeof value === 'string' ? value.split(',') : value;
            let uniqueMarkets = entityHierarchyList.filter(r => selectedRegions.includes(r.region) && userMarkets.includes(r.market));
            uniqueMarkets = uniqueMarkets.map(r => r.market).filter(unique);
            uniqueMarkets = uniqueMarkets.map(r => {return {id: r, key: r, value: r, label: r}});
            setUserMarketFilteredOptions(uniqueMarkets);
        }
	};

	const handleUserMarketChange = (event) => {
		const { target: { value } } = event;
		// setUserFilters(p => {return {...p, market: typeof value === 'string' ? value.split(',') : value}});
        
        setUserFilters(p => {
            return {
                ...p,
                market: [value],
                dealership: [],
                department: [],
            }
        });

        if (value.length <=0 ) {
            setUserDealershipFilteredOptions(userDealershipOptions);
            setUserDepartmentFilteredOptions(userDepartmentOptions);
        } else {
            setUserDealershipFilteredOptions([]);
            setUserDepartmentFilteredOptions([]);
            setUserFilters(p => {
                return {
                    ...p,
                    dealership: [],
                    department: [],
                }
            });

            // Generate Dealership Options
            const selectedRegions = userFilters.region
            const selectedMarkets = typeof value === 'string' ? value.split(',') : value;
            let uniqueDealerships = entityHierarchyList.filter(r => selectedRegions.includes(r.region) &&
                selectedMarkets.includes(r.market) &&
                userDealerships.includes(r.dealership));
            uniqueDealerships = uniqueDealerships.map(r => r.dealership).filter(unique);
            uniqueDealerships = uniqueDealerships.map(r => {return {id: r, key: r, value: r, label: r}});
            setUserDealershipFilteredOptions(uniqueDealerships);
        }
	};

	const handleUserDealershipChange = (event) => {
		const { target: { value } } = event;
		setUserFilters(p => {return {...p, dealership: typeof value === 'string' ? value.split(',') : value}});

        setUserFilters(p => {
            return {
                ...p,
                dealership: typeof value === 'string' ? value.split(',') : value,
                department: [],
            }
        });

        if (value.length <=0 ) {
            setUserDepartmentFilteredOptions(userDepartmentOptions);
        } else {
            setUserDepartmentFilteredOptions([]);
            setUserFilters(p => {
                return {
                    ...p,
                    department: [],
                }
            });

            // Generate Department Options
            const selectedRegions = userFilters.region;
            const selectedMarkets = userFilters.market;
            const selectedDealerships = typeof value === 'string' ? value.split(',') : value;
            let uniqueDepartments = entityHierarchyList.filter(r => selectedRegions.includes(r.region) && 
                selectedMarkets.includes(r.market) &&
                selectedDealerships.includes(r.dealership) &&
                userDepartments.includes(r.department));
            uniqueDepartments = uniqueDepartments.map(r => r.department).filter(unique);
            uniqueDepartments = uniqueDepartments.map(r => {return {id: r, key: r, value: r, label: r}});
            setUserDepartmentFilteredOptions(uniqueDepartments);
        }
	};

	const handleUserDepartmentChange = (event) => {
		const { target: { value } } = event;
		setUserFilters(p => {return {...p, department: typeof value === 'string' ? value.split(',') : value}});
	};

    // ----- RETURN
	return (
		<Box
            sx={{
                mb: 2,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifContent: 'flex0start',
                alignItems: 'center',
            }}
        >
            {/* DROPDOWNS */}

            <Box>
                {/* REGION FILTER */}

                {/* <FormControl size='small' sx={{ width: 200 }}>
                    <InputLabel>Region</InputLabel>
                    <Select
                        value={userFilters.region}
                        onChange={handleUserRegionChange}
                        input={<OutlinedInput label='Region' />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {userRegionFilteredOptions.map((name) => (
                            <MenuItem key={name.id} value={name.value} title={name.label}>
                                <Checkbox checked={userFilters.region.indexOf(name.value) > -1} />
                                <ListItemText primary={name.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

                <FormControl size='small' sx={{ width: 200 }}>
                    <InputLabel>Region</InputLabel>
                    <Select
                        label='Region'
                        value={userFilters.region}
                        onChange={handleUserRegionChange}
                    >
                        <MenuItem value=''><i>None</i></MenuItem>
                        {userRegionFilteredOptions.map((name) => (
                            <MenuItem key={name.id} value={name.value} title={name.label}>
                                {name.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                {/* MARKET FILTER */}

                {/* <FormControl size='small' sx={{ ml: 1, width: 200 }}>
                    <InputLabel>Market</InputLabel>
                    <Select
                        value={userFilters.market}
                        onChange={handleUserMarketChange}
                        input={<OutlinedInput label='Market' />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {userMarketFilteredOptions.map((name) => (
                            <MenuItem key={name.id} value={name.value} title={name.label}>
                                <Checkbox checked={userFilters.market.indexOf(name.value) > -1} />
                                <ListItemText primary={name.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

                <FormControl size='small' sx={{ ml: 1, width: 200 }}>
                    <InputLabel>Market</InputLabel>
                    <Select
                        label='Market'
                        value={userFilters.market}
                        onChange={handleUserMarketChange}
                    >
                        <MenuItem value=''><i>None</i></MenuItem>
                        {userMarketFilteredOptions.map((name) => (
                            <MenuItem key={name.id} value={name.value} title={name.label}>
                                {name.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                {/* DEALERSHIP FILTER */}

                <FormControl size='small' sx={{ ml: 1, width: 200 }}>
                    <InputLabel>Dealership</InputLabel>
                    <Select
                        multiple
                        value={userFilters.dealership}
                        onChange={handleUserDealershipChange}
                        input={<OutlinedInput label='Dealership' />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {userDealershipFilteredOptions.map((name) => (
                            <MenuItem key={name.id} value={name.value} title={name.label} sx={{ fontSize: 10 }}>
                                <Checkbox checked={userFilters.dealership.indexOf(name.value) > -1} />
                                <ListItemText primary={name.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                {/* DEPARTMENT FILTER */}

                <FormControl size='small' sx={{ ml: 1, width: 200 }}>
                    <InputLabel>Department</InputLabel>
                    <Select
                        multiple
                        value={userFilters.department}
                        onChange={handleUserDepartmentChange}
                        input={<OutlinedInput label='Department' />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {userDepartmentFilteredOptions.map((name) => (
                            <MenuItem key={name.id} value={name.value} title={name.label}>
                                <Checkbox checked={userFilters.department.indexOf(name.value) > -1} />
                                <ListItemText primary={name.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>


            {/* LOAD FILETER BUTTON */}

            <Button
                variant='contained'
                disableElevation
                onClick={handledLoadDataClick}
                sx={{
                    ml: 1,
                    height: 39,
                }}
            >
                Load Data
            </Button>

            
            {/* DATE FILTER */}

            <Button
                variant={userFilters.dateFiltered ? 'contained' : 'outlined'}
                disableElevation
                startIcon={userFilters.dateFiltered ? <CalendarCheckIcon /> : <CalendarXIcon />}
                onClick={() => setDialogOpenDateFilters(true)}
                sx={{
                    ml: 1,
                    height: 40,
                }}
            >
                Date Filter
            </Button>
            
            
            {/* SEARCH */}

            <TextField
                label='Search'
                placeholder='Search'
                size='small'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                sx={{
                    ml: 1,
                    flexGrow: 1
                }}
            />

            {/* RESET */}

            <Button
                variant='contained'
                disableElevation
                onClick={resetFilters}
                sx={{
                    ml: 1,
                    height: 39,
                }}
            >
                <FilterOffIcon />
            </Button>


            {/* DATE FILTER DIALOG */}

            <Dialog
                fullWidth
                maxWidth='xs'
                open={dialogOpenDateFilters}
                onClose={() => setDialogOpenDateFilters(false)}
            >
                <DialogTitle>DATE FILTERS</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns }>
                        <Box sx={{ p: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel>Date Field</InputLabel>
                                <Select
                                    label='Date Field'
                                    value={userFilters.dateField}
                                    onChange={(e) => setUserFilters(p => {return {...p, dateField: e.target.value}})}
                                >
                                    <MenuItem value=''><em>-none-</em></MenuItem>
                                    <MenuItem value='date'>Creation Date</MenuItem>
                                    <MenuItem value='completedDate'>Completed Date</MenuItem>
                                    <MenuItem value='escalationCutoff'>Escalation Cutoff Date</MenuItem>
                                    <MenuItem value='orderAgeCutoff'>Order Age Cutoff Date</MenuItem>
                                    <MenuItem value='reviewCutoff'>Review Cutoff Date</MenuItem>
                                </Select>
                            </FormControl>

                            <DesktopDatePicker
                                label='From'
                                inputFormat='yyyy-MM-dd'
                                renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
                                value={new Date(userFilters?.dateFrom)}
                                onChange={(newValue) => setUserFilters(p => {return {...p, dateFrom: format(newValue, `yyyy-MM-dd'T'00:00:00.000'Z'`)}})}
                            />

                            <DesktopDatePicker
                                label='From'
                                inputFormat='yyyy-MM-dd'
                                renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
                                value={new Date(userFilters?.dateTo)}
                                onChange={(newValue) => setUserFilters(p => {return {...p, dateTo: format(newValue, `yyyy-MM-dd'T'23:59:59.999'Z'`)}})}
                            />
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpenDateFilters(false)}>Close</Button>
                    <Button onClick={handleDialogResetDateFilters}>Reset</Button>
                    <Button onClick={handleDateFilterApplyClick}>Apply Date Filter</Button>
                </DialogActions>
            </Dialog>

            <Notif
                open={notifInfo.open}
                severity={notifInfo.severity}
                message={notifInfo.message}
                onCloseCallback={setNotifInfo}
            />
		</Box>
	);
};

export default Filters;
