import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { Box } from '@mui/system';

const NoAccess = () => {
    const navigate = useNavigate();

    const handleBackHomeClick = (e) => {
        e.preventDefault();
        navigate('/');
    }

	return (
        <Box sx={{
            width: '100%',
            margin: '0 auto'
        }}>
            <Alert severity='error'>
                You do not have access to this page! <Link href='#' underline='none' onClick={(e) => handleBackHomeClick(e)}>Back to home page</Link>
            </Alert>
        </Box>
	);
};

export default NoAccess;
