import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import { Box, Grid, Typography } from '@mui/material';
import {
    InsertDriveFileOutlined as FileBlankIcon,
    DescriptionOutlined as FileIcon,
    InventoryOutlined as TaskCheckedIcon,
    SettingsOutlined as GearIcon,
} from '@mui/icons-material';


import { useAppContext } from '../contexts/appContext';


const tabs = [
    { name: 'Without Value', icon: <FileBlankIcon fontSize='large' /> },
    { name: 'With Value', icon: <FileIcon fontSize='large' /> },
    { name: 'Closed', icon: <TaskCheckedIcon fontSize='large' /> },
    { name: 'Admin', icon: <GearIcon fontSize='large' /> },
]


const Nav = () => {
    const navigate = useNavigate();

    // ----- CONTEXTS
    const { userInfo,  activeTabMain, setActiveTabMain } = useAppContext();


    // ----- EFFECTS
    useEffect(() => {
        
    }, []);


    // ----- FUNCTIONS
    const handleMainTabClick = (num) => {
        if (num === 3 && !userInfo.admin) {
            return;
        } else if (num === 3) {
            setActiveTabMain(num);
            navigate('/admin');
            return;
        }

        setActiveTabMain(num);
    }


    // ----- RETURN
	return (
		<Box sx={{width: '100%'}}>
            <Grid container>
                {
                    tabs.map((tab, index) => {
                        return (
                            <Grid 
                                key={index}
                                item xs={12}
                                sm={3}
                                onClick={() => handleMainTabClick(index)}
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        bgcolor: '#C0C0C0'
                                    },
                                    borderBottom: activeTabMain === index ? '4px solid #1976d2' : '4px solid #E5E7E9',
                                    color: activeTabMain === index ? '#1976d2' : 'inherit',
                                    bgcolor: activeTabMain === index ? '#D7DBDD' : '#E5E7E9',
                                }}
                            >
                            {
                                index !== 3 &&
                                <>
                                    {tab.icon}
                                    <Typography sx={{ mt: '.5em' }}>{tab.name}</Typography>
                                </>
                            }
                            {
                                index === 3 && userInfo.admin &&
                                <>
                                    {tab.icon}
                                    <Typography sx={{ mt: '.5em' }}>{tab.name}</Typography>
                                </>
                            }
                            </Grid>
                        )
                    })
                }
                
            </Grid>
        </Box>
	);
};

export default Nav;
