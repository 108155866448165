import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';


import { Box } from '@mui/system';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Alert, Button, IconButton, Tooltip, Typography } from '@mui/material';
import {
    Search as SearchIcon,
} from '@mui/icons-material';


import {useAppContext} from '../contexts/appContext';
import Filters from '../comps/Filters';
import Nav from '../comps/Nav';
import WipView from '../comps/WipView';
import Notif from '../comps/Notif';



function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}


const Home = () => {
    const cols = [
        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: ({ row }) => {
                return [
                    <IconButton onClick={() => handleOpenDialogWipView(row)}>
                        <SearchIcon />
                    </IconButton>
                ]
            }
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: ({ row }) => <span style={{ color: row.status.toLowerCase() === 'reviewed' || row.status.toLowerCase() === 'closed' ? 'green' : 'red' }}>{row.status}</span>
        },
        {
            field: 'wipNo',
            headerName: 'Order No',
            width: 90,
        },
        {
            field: 'customer',
            headerName: 'Customer',
            width: 200,
        },
        {
            field: 'account',
            headerName: 'Account'
        },
        {
            field: 'partsWip',
            headerName: 'Parts Cost',
            type: 'number',
        },
        {
            field: 'labWip',
            headerName: 'Labour Cost',
            type: 'number'
        },
        {
            field: 'sublet',
            headerName: 'Other Cost',
            type: 'number'
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 100,
            // type: 'date',
            renderCell: ({ row }) => <span>{format(new Date(row.date), 'yyyy-MM-dd')}</span>
        },
        {
            field: 'age',
            headerName: 'Age',
            width: 80,
        },
        {
            field: 'regNo',
            headerName: 'Reg No',
        },
        {
            field: 'jobNo',
            headerName: 'Job No',
        },
        {
            field: 'region',
            headerName: 'Region',
            width: 150,
        },
        {
            field: 'market',
            headerName: 'Market',
        },
        {
            field: 'dealership',
            headerName: 'Dealership',
            width: 180,
        },
        {
            field: 'department',
            headerName: 'Department',
            width: 180,
        },
        {
            field: 'owningOperator',
            headerName: 'Owning Operator',
            width: 180,
        },
        {
            field: 'escalationCutoff',
            headerName: 'Escalation Cutoff',
            width: 160,
            // type: 'date',
            renderCell: ({ row }) => <span>{row.escalationCutoff ? format(new Date(row.escalationCutoff), 'yyyy-MM-dd') : ''}</span>
        },
        {
            field: 'orderAgeCutoff',
            headerName: 'Order Age Cutoff',
            width: 160,
            // type: 'date',
            renderCell: ({ row }) => <span>{row.orderAgeCutoff ? format(new Date(row.orderAgeCutoff), 'yyyy-MM-dd') : ''}</span>
        },
        {
            field: 'reviewCutoff',
            headerName: 'Review Cutoff',
            width: 160,
            // type: 'date',
            renderCell: ({ row }) => <span>{row.reviewCutoff ? format(new Date(row.reviewCutoff), 'yyyy-MM-dd') : ''}</span>
        },
        {
            field: 'comment',
            headerName: 'Last Review Comment',
            width: 240,
            renderCell: ({ row }) => {
                return [
                    <Tooltip key={row.id} title={row.comment}>
                        <span>{row.comment}</span>
                    </Tooltip>
                ]
            }
        },
        {
            field: 'reviewedDate',
            headerName: 'Last Reviewed',
            width: 160,
            // type: 'date',
            renderCell: ({ row }) => <span>{row.reviewedDate ? format(new Date(row.reviewedDate), 'yyyy-MM-dd hh:mm a') : ''}</span>
        },
        {
            field: 'reviewedBy',
            headerName: 'Reviewed By',
            width: 180,
        },
        {
            field: 'completedDate',
            headerName: 'Completed Date',
            width: 140,
            renderCell: ({ row }) => <span>{row.completedDate ? format(new Date(row.completedDate), 'yyyy-MM-dd') : ''}</span>
        },
        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: ({ row }) => {
                return [
                    <IconButton onClick={() => handleOpenDialogWipView(row)}>
                        <SearchIcon />
                    </IconButton>
                ]
            }
        },
    ];


    // ----- CONTEXTS
    const {
        userInfo, activeTabMain,
        notifInfo, setNotifInfo,
        userFilters,
        searchText,
        isLoadingWipData,
        // setIsLoadingWipData,
        isLoadingNotif,
        wipData, setWipData,
        wipWithoutValue, setWipWithoutValue, 
        wipWithValue, setWipWithValue,
        wipClosed, setWipClosed, 
    } = useAppContext();


    // ----- STATES
    const [activeWipData, setActiveWipData] = useState([])
    const [dialogOpenWipView, setDialogOpenWipView] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [sendResultDisabled, setSendResultDisabled] = useState(true);


    // ----- EFFECTS
    useEffect(() => {
        if (wipData > 0) return;

        // getWipData();
        
        // eslint-disable-next-line
    }, [userInfo]);


    useEffect(() => {
        switch (activeTabMain) {
            case 0:
                setActiveWipData(wipWithoutValue)
                if (wipWithoutValue.filter(r => r.status === 'Not Yet Reviewed').length > 0 || wipWithoutValue.length <= 0) {
                    setSendResultDisabled(true);
                } else {
                    setSendResultDisabled(false);
                }
                break;
            case 1:
                setActiveWipData(wipWithValue)
                if (wipWithValue.filter(r => r.status === 'Not Yet Reviewed').length > 0 || wipWithValue.length <= 0) {
                    setSendResultDisabled(true);
                } else {
                    setSendResultDisabled(false);
                }
                break;
            case 2:
                setActiveWipData(wipClosed)
                if (wipClosed.filter(r => r.status === 'Not Yet Reviewed').length > 0 || wipClosed.length <= 0) {
                    setSendResultDisabled(true);
                } else {
                    setSendResultDisabled(false);
                }
                break;
            default:
                setActiveWipData(wipWithoutValue)
                if (wipWithoutValue.filter(r => r.status === 'Not Yet Reviewed').length > 0 || wipWithoutValue.length <= 0) {
                    setSendResultDisabled(true);
                } else {
                    setSendResultDisabled(false);
                }
                break;
        }
    }, [activeTabMain, wipWithoutValue, wipWithValue, wipClosed]);


    // ----- API FUNCTIONS
    // const getWipData = () => {
    //     setIsLoading(true);
    //     let result = [];
    //     let url = '';
    //     let key = '';
    //     let rowCount = 0;
    //     const pageLimit = 1000;

    //     if (window.location.hostname === 'localhost') {
    //         url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
    //         key = process.env.REACT_APP_API_KEY_DEV;
    //     } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
    //         url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
    //         key = process.env.REACT_APP_API_KEY_DEV;
    //     } else {
    //         url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
    //         key = process.env.REACT_APP_API_KEY_PROD;
    //     }

    //     axios({
    //         method: 'get',
    //         url: `${url}/getWipByEmailCount/?code=${key}&email=${userInfo.email}`
    //     })
    //     .then(res => {
    //         rowCount = res.data.data.recordset[0].count;
    //     })
    //     .then(() => {
    //         // IF COUNT IS LESS THAN LIMIT
    //         if (rowCount <= pageLimit) {
    //             axios({
    //                 method: 'get',
    //                 url: `${url}/getWipByEmail/?code=${key}&email=${userInfo.email}`
    //             })
    //             .then(res => {
    //                 result = res.data.data.recordset;
    //                 setWipData(result);
    //             })
    //             .then(() => {
    //                     setWipWithoutValue(wipData.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0 && r.status.toLowerCase() !== 'closed'));
    //                     setWipWithValue(wipData.filter(r => (r.partsWip > 0 || r.labWip > 0 || r.sublet > 0) && r.status.toLowerCase() !== 'closed'));
    //                     setWipClosed(wipData.filter(r => r.status.toLowerCase() === 'compelted' || r.status.toLowerCase() === 'closed'));
    //             });

    //         // IF COUNT IS MORE THAN LIMIT
    //         } else {
    //             for (let i=0; i<= rowCount; i+=pageLimit) {
    //                 axios({
    //                     method: 'get',
    //                     url: `${url}/getWipByEmailPaginated/?code=${key}&email=${userInfo.email}&offset=${i}&limit=${pageLimit}`
    //                 })
    //                 .then(res => {
    //                     result = res.data.data.recordset;
    //                     setWipData(p => {
    //                         let newData = p;
    
    //                         result.forEach(r => {
    //                             newData.push(r);
    //                         });
    //                         return newData;
    //                     });
    //                 })
    //                 .then(() => {
    //                         setWipWithoutValue(wipData.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0 && r.status.toLowerCase() !== 'closed'));
    //                         setWipWithValue(wipData.filter(r => (r.partsWip > 0 || r.labWip > 0 || r.sublet > 0) && r.status.toLowerCase() !== 'closed'));
    //                         setWipClosed(wipData.filter(r => r.status.toLowerCase() === 'compelted' || r.status.toLowerCase() === 'closed'));
    //                 })
    //                 .then(() => {
    //                     if (rowCount > i && rowCount < i + pageLimit) {
    //                         setIsLoading(false);
    //                     }
    //                 })
    //                 .then(() => {
    //                     setNotifInfo({
    //                         open: true,
    //                         severity: 'warning',
    //                         message: `Data is being loaded incrementally due to large volume.`
    //                     });
    //                 });
    //             }
    //         }
            
    //     })
    //     .then(() => {
    //         setIsLoading(false);
    //     })
    //     .catch((error) => {
    //         setNotifInfo({
    //             open: true,
    //             severity: 'error',
    //             message: `Error! ${error}`
    //         });
    //     });
    // }


    // ----- FUNCTIONS
    const handleOpenDialogWipView = (row) => {
        setSelectedRow(row);
        setDialogOpenWipView(true);
    }

    const handleCloseDialogWipView = () => {
        setSelectedRow(null);
        setDialogOpenWipView(false)
    }

    const updateLocalWipInfo = (newWipInfo) => {
        const id = newWipInfo.id;
        const wipDataIndex = wipData.findIndex(r => r.id === id);
        wipData[wipDataIndex] = newWipInfo;
    }


    // ----- RETURN
	return (
		<Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            <Nav />
            <Box sx={{ p: 2, width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Filters />
                <Box sx={{ flexGrow: 1, width: '100%' }}>
                    <DataGrid
                        rows={activeWipData}
                        columns={cols}
                        loading={isLoadingWipData}
                        hideFooterSelectedRowCount
                        autoPageSize={true}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </Box>
                <Box sx={{ pt: 2, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='subtitle'>
                        {
                            (searchText.length > 0 && userFilters.dateFiltered) &&
                            <Alert severity='warning'>
                                Note: Filter(s) were applied to the current view
                            </Alert>
                        }
                        {
                            (isLoadingNotif) &&
                            <Alert severity='warning'>
                                Note: Data is still loading...
                            </Alert>
                        }
                    </Typography>
                    <Button
                        variant={sendResultDisabled ? 'outlined' : 'contained'}
                        color='secondary'
                        disabled={sendResultDisabled}
                    >
                        Send Result
                    </Button>
                </Box>
            </Box>


            {/* VIEW WIP DIALOG */}

            <WipView
                open={dialogOpenWipView}
                row={selectedRow}
                onCloseCallback={handleCloseDialogWipView}
                updateCallback={updateLocalWipInfo}
            />

            <Notif
                open={notifInfo.open}
                severity={notifInfo.severity}
                message={notifInfo.message}
                onCloseCallback={setNotifInfo}
            />
        </Box>
	);
};

export default Home;
