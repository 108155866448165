import React, { forwardRef } from 'react';

import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Notif = ({ open, severity, message, onCloseCallback }) => {
	const handleClose = () => {
		onCloseCallback(p => {
			return {
				...p,
				open: false,
			}
		});
	};

	return (
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
	);
};

export default Notif;
