
import * as msal from '@azure/msal-browser';

let redirect_uri = '';
if (window.location.hostname === 'localhost') {
    redirect_uri = process.env.REACT_APP_AZ_REDIRECT_URI_LOCAL;
} else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
    redirect_uri = process.env.REACT_APP_AZ_REDIRECT_URI_DEV;
} else {
    redirect_uri = process.env.REACT_APP_AZ_REDIRECT_URI_PROD;
}

export const config = {
    appId: process.env.REACT_APP_AZ_APP_ID,
    redirectUri: redirect_uri,
    authority: process.env.REACT_APP_AZ_AUTHORITY,
    scopes: ['User.Read']
}
const msalConfig = {
    auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
}

export const msalInstance = new msal.PublicClientApplication(msalConfig);