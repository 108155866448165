import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const UseAppProvider = ({ children }) => {
    // ----- STATES

    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [loginLoading, setLoginLoading] = useState(false);
    const [activeTabMain, setActiveTabMain] = useState(0);
    const [notifInfo, setNotifInfo] = useState({open: false, severity: 'info', message: ''});
    const [isLoadingWipData, setIsLoadingWipData] = useState(false);
    const [isLoadingNotif, setIsLoadingNotif] = useState(false);

    // Wip data
    const [wipData, setWipData] = useState([]);
    const [wipFilteredData, setWipFilteredData] = useState([]);
    const [wipWithoutValue, setWipWithoutValue] = useState([]);
    const [wipWithValue, setWipWithValue] = useState([]);
    const [wipClosed, setWipClosed] = useState([]);
    const [userList, setUserList] = useState([]);

    // Full lists
    const [regionList, setRegionList] = useState([]);
    const [marketList, setMarketList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [dealershipList, setDealershipList] = useState([]);
    const [entityHierarchyList, setEntityHierarchyList] = useState([])

    // Options
    const [regionOptions, setRegionOptions] = useState([]);
    const [marketOptions, setMarketOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [dealershipOptions, setDealershipOptions] = useState([]);

    // User specific options
    const [userFilters, setUserFilters] = useState({
        region: [],
        market: [],
        dealership: [],
        department: [],
        dateFiltered: false,
        dateField: '',
        dateFrom: new Date(),
        dateTo: new Date(),
    })
    const [searchText, setSearchText] = useState('');
    const [userRegionOptions, setUserRegionOptions] = useState([]);
    const [userMarketOptions, setUserMarketOptions] = useState([]);
    const [userDepartmentOptions, setUserDepartmentOptions] = useState([]);
    const [userDealershipOptions, setUserDealershipOptions] = useState([]);


    const [propertyList, setPropertyList] = useState([]);


    // ----- EFFECTS

    useEffect(() => {
        if (!user) {
            setLoginLoading(false);
            return;
        }

        getUserInfo();

        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (!userInfo) return;

        if (regionOptions.length <= 0) getRegionOptions();
        if (marketOptions.length <= 0) getMarketOptions();
        if (departmentOptions.length <= 0) getDepartmentOptions();
        if (dealershipOptions.length <= 0) getDealershipOptions();
        if (entityHierarchyList.length <=0) getEntityHierarchyList();

        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (!userInfo) return;
        if (wipData.length <= 0) return;
        
        setWipFilteredData(wipData);
        getWipWithoutValue(wipData);
        getWipWithValue(wipData);
        getWipClosed(wipData);

        // eslint-disable-next-line
    }, [wipData]);

    useEffect(() => {
        if (!userInfo) return;

        let regions = [];
        let markets = [];
        let dealerships = [];
        let departments = [];

        if (userInfo.region?.length > -1) {
            regions = userInfo.region.split(';');
            regions = regions.map(r => {return {id: r, value: r, label: r}});
            setUserRegionOptions(regions);
        }

        if (userInfo.market?.length > -1) {
            markets = userInfo.market.split(';');
            markets = markets.map(r => {return {id: r, value: r, label: r}});
            setUserMarketOptions(markets);
        }

        if (userInfo.dealership?.length > -1) {
            dealerships = userInfo.dealership.split(';');
            dealerships = dealerships.map(r => {return {id: r, value: r, label: r}});
            setUserDealershipOptions(dealerships);
        }

        if (userInfo.department?.length > -1) {
            departments = userInfo.department.split(';');
            departments = departments.map(r => {return {id: r, value: r, label: r}});
            setUserDepartmentOptions(departments);
        }
        
    }, [userInfo]);


    // ----- API FUNCTIONS

    const getUserInfo = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getUserByEmail/?code=${key}&email=${user}`
        })
        .then((res) => {
            let result = res.data.data.recordset[0];
            setUserInfo(result);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoginLoading(false);
        })

    }

    const getEntityHierarchyList = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }
        let result;

        axios({
            method: 'get',
            url: `${url}/getEntityHierarchy/?code=${key}`
        })
        .then((res) => {
            result = res.data.data.recordset;
            result = result.sort((a, b) => 
                a.region.localeCompare(b.region) || a.market.localeCompare(b.market) ||
                a.dealership.localeCompare(b.dealership) || a.department.localeCompare(b.department));
            setEntityHierarchyList(result);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const getRegionOptions = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getRegions/?code=${key}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => a.name.localeCompare(b.name));
            result = result.map(r => {return {key: r.id, value: r.name, label: r.name}});
            setRegionOptions(result);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const getMarketOptions = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getMarkets/?code=${key}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => a.name.localeCompare(b.name));
            result = result.map(r => {return {key: r.id, value: r.name, label: r.name}});
            setMarketOptions(result);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const getDepartmentOptions = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getDepartments/?code=${key}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => a.name.localeCompare(b.name));
            result = result.map(r => {return {key: r.id, value: r.name, label: r.name}});
            setDepartmentOptions(result);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const getDealershipOptions = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getDealerships/?code=${key}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => a.name.localeCompare(b.name));
            result = result.map(r => {return {key: r.id, value: r.name, label: r.name}});
            setDealershipOptions(result);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    // WIP Data

    const getWipWithoutValue = (data) => {
        setWipWithoutValue(data.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0 && r.status.toLowerCase() !== 'closed'));
    }

    const getWipWithValue = (data) => {
        setWipWithValue(data.filter(r => (r.partsWip > 0 || r.labWip > 0 || r.sublet > 0) && r.status.toLowerCase() !== 'closed'));
    }

    const getWipClosed = (data) => {
        setWipClosed(data.filter(r => r.status.toLowerCase() === 'completed' || r.status.toLowerCase() === 'closed'));
    }

    const applyFilters = () => {
        
        // -------------------------------------- WITHOUT VALUE

        let newListWithoutValue = wipData;
        // --- Date filters
        if (userFilters.dateFiltered) {
            newListWithoutValue = newListWithoutValue.filter(r => 
                    new Date(r[userFilters.dateField]) >= new Date(userFilters.dateFrom) &&
                    new Date(r[userFilters.dateField]) <= new Date(userFilters.dateTo)
                )
        }
        // Search text filter
        newListWithoutValue = newListWithoutValue.filter(r => r.partsWip <= 0 && r.labWip <= 0 && r.sublet <= 0&& r.status.toLowerCase() !== 'closed');
        if (searchText !== '') {
            newListWithoutValue = newListWithoutValue.filter(r => 
                r.id?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.wipNo?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.customer?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.account?.toString().toLowerCase().includes(searchText.toLowerCase())
            )
        }
        // --- Region filters
        // if (userFilters.region.length > 0) {
        //     newListWithoutValue = newListWithoutValue.filter(r => userFilters.region.filter(d => d === r.region).length > 0)
        // }
        // --- Market filters
        // if (userFilters.market.length > 0) {
        //     newListWithoutValue = newListWithoutValue.filter(r => userFilters.market.filter(d => d === r.market).length > 0)
        // }
        // --- Dealership filters
        // if (userFilters.dealership.length > 0) {
        //     newListWithoutValue = newListWithoutValue.filter(r => userFilters.dealership.filter(d => d === r.dealership).length > 0)
        // }
        // --- Department filters
        // if (userFilters.department.length > 0) {
        //     newListWithoutValue = newListWithoutValue.filter(r => userFilters.department.filter(d => d === r.department).length > 0)
        // }
        setWipWithoutValue(newListWithoutValue)

        
        // -------------------------------------- WITH VALUE

        let newListWithValue = wipData;
        // --- Date filters
        if (userFilters.dateFiltered) {
            newListWithValue = newListWithValue.filter(r => 
                    new Date(r[userFilters.dateField]) >= new Date(userFilters.dateFrom) &&
                    new Date(r[userFilters.dateField]) <= new Date(userFilters.dateTo)
                )
        }
        // Search text filters
        newListWithValue = newListWithValue.filter(r => (r.partsWip > 0 || r.labWip > 0 || r.sublet > 0) && r.status.toLowerCase() !== 'closed');
        if (searchText !== '') {
            newListWithValue = newListWithValue.filter(r => 
                r.id?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.wipNo?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.customer?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.account?.toString().toLowerCase().includes(searchText.toLowerCase())
            )
        }
        // --- Region filters
        // if (userFilters.region.length > 0) {
        //     newListWithValue = newListWithValue.filter(r => userFilters.region.filter(d => d === r.region).length > 0)
        // }
        // --- Market filters
        // if (userFilters.market.length > 0) {
        //     newListWithValue = newListWithValue.filter(r => userFilters.market.filter(d => d === r.market).length > 0)
        // }
        // --- Dealership filters
        // if (userFilters.dealership.length > 0) {
        //     newListWithValue = newListWithValue.filter(r => userFilters.dealership.filter(d => d === r.dealership).length > 0)
        // }
        // --- Department filters
        // if (userFilters.department.length > 0) {
        //     newListWithValue = newListWithValue.filter(r => userFilters.department.filter(d => d === r.department).length > 0)
        // }
        setWipWithValue(newListWithValue);

        
        
        // -------------------------------------- CLOSED

        let newListClosed = wipData;
        // Search text filters
        newListClosed = newListClosed.filter(r => r.status.toLowerCase() === 'completed' || r.status.toLowerCase() === 'closed');
        if (searchText !== '') {
            newListClosed = newListClosed.filter(r => 
                r.id?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.wipNo?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.customer?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                r.account?.toString().toLowerCase().includes(searchText.toLowerCase())
            )
        }
        // --- Date filters
        if (userFilters.dateFiltered) {
            newListClosed = newListClosed.filter(r => 
                    new Date(r[userFilters.dateField]) >= new Date(userFilters.dateFrom) &&
                    new Date(r[userFilters.dateField]) <= new Date(userFilters.dateTo)
                )
        }
        // --- Region filters
        // if (userFilters.region.length > 0) {
        //     newListClosed = newListClosed.filter(r => userFilters.region.filter(d => d === r.region).length > 0)
        // }
        // --- Market filters
        // if (userFilters.market.length > 0) {
        //     newListClosed = newListClosed.filter(r => userFilters.market.filter(d => d === r.market).length > 0)
        // }
        // --- Dealership filters
        // if (userFilters.dealership.length > 0) {
        //     newListClosed = newListClosed.filter(r => userFilters.dealership.filter(d => d === r.dealership).length > 0)
        // }
        // --- Department filters
        // if (userFilters.department.length > 0) {
        //     newListClosed = newListClosed.filter(r => userFilters.department.filter(d => d === r.department).length > 0)
        // }
        setWipClosed(newListClosed)

    }


    // ----- RETURN

    return (
        <AppContext.Provider
            value={{
                user, setUser,
                userInfo, setUserInfo,
                loginLoading, setLoginLoading,
                activeTabMain, setActiveTabMain,
                notifInfo, setNotifInfo,
                isLoadingWipData, setIsLoadingWipData,
                isLoadingNotif, setIsLoadingNotif,

                userList, setUserList,

                wipData, setWipData,
                wipFilteredData, setWipFilteredData,
                wipWithoutValue, setWipWithoutValue,
                wipWithValue, setWipWithValue,
                wipClosed, setWipClosed,

                regionList, setRegionList,
                marketList, setMarketList,
                departmentList, setDepartmentList,
                dealershipList, setDealershipList,
                entityHierarchyList, setEntityHierarchyList,

                userFilters, setUserFilters,
                searchText, setSearchText,
                applyFilters,

                regionOptions, setRegionOptions,
                marketOptions, setMarketOptions,
                departmentOptions, setDepartmentOptions,
                dealershipOptions, setDealershipOptions,

                userRegionOptions, setUserRegionOptions,
                userMarketOptions, setUserMarketOptions,
                userDepartmentOptions, setUserDepartmentOptions,
                userDealershipOptions, setUserDealershipOptions,

                propertyList, setPropertyList,

                getWipWithoutValue,
                getWipWithValue,
                getWipClosed,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}