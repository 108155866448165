import React from 'react';
import { config, msalInstance } from '../msal/config';


import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import WindowIcon from '@mui/icons-material/Window';


import { useAppContext } from '../contexts/appContext';
import logo from '../assets/logo.png';
import bg from '../assets/inchcape-bg1.png';
import axios from 'axios';


const Login = () => {
    // ----- CONTEXTS
    const { user, setUser, userInfo, setUserInfo, loginLoading, setLoginLoading } = useAppContext();


    // ----- FUNCTIONS
    const userLogin = async () => {
        setLoginLoading(true);

        try {
            const res = await msalInstance.loginPopup({
                scopes: config.scopes,
                prompt: 'select_account'
            });

            const userEmail = res.account.username;
            setUser(userEmail);
            window.sessionStorage.setItem('user', JSON.stringify(res.account.username));
            logLoginActivity(userEmail);
        } catch (error) {
            setUser(null);
            setUserInfo(null);
            console.log(error);
        }
    }

    const logLoginActivity = (email) => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'put',
            url: `${url}/addAppLoginLogout/?code=${key}`,
            data: {
                email: email,
                activity: 'login'
            }
        })
        .catch((error) => {
            console.log(error.message);
        })
    }


    // ----- RETURN
	return (
        <div style={{
            height: '100vh',
            width: '100%',
            // backgroundColor: '#31466C',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${bg})`
        }}>
            <div style={{
                width: '540px',
                margin: '0 auto',
            }}>
                <Paper elevation={5} sx={{
                    p: 5,
                    m: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}>
                        <img src={logo} alt='Inchcape Logo' height='130' width='130' />
                        <Box sx={{ ml: 3, flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <Typography variant='h5'>Inchcape</Typography>
                            <Typography variant='h3'><strong>WIP REPORT</strong></Typography>
                            <Typography variant='overline'>version 1.1</Typography>
                        </Box>
                    </Box>

                    <Button
                        fullWidth
                        size='large'
                        variant='contained'
                        startIcon={<WindowIcon />}
                        onClick={() => userLogin()}
                        sx={{
                            mt: 5,
                            fontSize: 20
                        }}
                    >
                        Login with Microsoft
                    </Button>
                    
                    {
                        user && user.length > 0 && !userInfo && !loginLoading &&
                        <Alert severity='error' sx={{ mt: 2, width: '100%' }}>You do not have permission to access this resource!</Alert>
                    }
                </Paper>
            </div>
            
        </div>
	);
};

export default Login;
