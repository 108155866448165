import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';


import { Button, Grid, Paper, TextField } from '@mui/material';
import { Box, Container } from '@mui/system';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import { useAppContext } from '../../contexts/appContext';


import Notif from '../Notif';


const Properties = () => {
    // ----- CONTEXTS
    const {
        userInfo,
        notifInfo, setNotifInfo,
        propertyList, setPropertyList,
    } = useAppContext();


    // ----- STATES
    const [activeRegionTab, setActiveRegionTab] = useState(0);
    const [activeRegionProperties, setActiveRegionProperties] = useState({});
    const [actionType, setActionType] = useState('view');


    // ----- EFFECTS
    useEffect(() => {
        if (propertyList.length <= 0) getProperties();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (propertyList.length <= 0) return;
        setActiveRegionProperties(propertyList[0]);
    }, [propertyList]);


    // ----- API FUNCTIONS
    const getProperties = () => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }

        axios({
            method: 'get',
            url: `${url}/getProperties/?code=${key}`
        })
        .then((res) => {
            let result = res.data.data.recordset;
            result = result.sort((a, b) => a.region.localeCompare(b.region));
            setPropertyList(result);
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error}`
            });
        })
    }

    const updateProperties = (payload) => {
        let url = '';
        let key = '';
        if (window.location.hostname === 'localhost') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_LOCAL;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else if (window.location.hostname === 'inchcape-wip-report-dev.azurewebsites.net') {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_DEV;
            key = process.env.REACT_APP_API_KEY_DEV;
        } else {
            url = process.env.REACT_APP_API_ROOT_ENDPOINT_PROD;
            key = process.env.REACT_APP_API_KEY_PROD;
        }
        
        axios({
            method: 'put',
            url: `${url}/updateProperties/?code=${key}&id=${payload.id}`,
            data: {
                username: payload.username,
                orderAgeCutoff: payload.orderAgeCutoff,
                daysToReview: payload.daysToReview,
                escalationCutoff: payload.escalationCutoff
            }
        })
        .then((res) => {
            setNotifInfo({
                open: true,
                severity: 'success',
                message: `Properties was successfully updated!`
            });
        })
        .then(() => {
            getProperties();
        })
        .catch((error) => {
            setNotifInfo({
                open: true,
                severity: 'error',
                message: `Error! ${error.message}`
            });
        })
    }


    // ----- FUNCTIONS
    const handleRegionButtonClick = (ix, id) => {
        setActiveRegionTab(ix);
        setActiveRegionProperties(propertyList.filter(r => r.id ===  id)[0]);
    }

    const handleViewEditClick = () => {
        if (actionType === 'view') {
            // Enter edit mode

            setActionType('edit');
        } else {
            // Save
            const payload = {
                id: activeRegionProperties.id,
                username: userInfo.name,
                orderAgeCutoff: activeRegionProperties.orderAgeCutoff,
                daysToReview: activeRegionProperties.daysToReview,
                escalationCutoff: activeRegionProperties.escalationCutoff,
            }

            updateProperties(payload);
            setActionType('view');
        }
    }

    const handleDiscardClick = () => {
        setActionType('view');
    }


    // ----- RETURN
	return (
        <Container disableGutters maxWidth='md' sx={{ height: '100%', }}>
            <Grid container sx={{ height: '100%', }}>
                <Grid item xs={12} sm={4} sx={{ pr: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {
                        propertyList.length > 0 &&
                        propertyList.map((r, index) => {
                            return (
                                <Button key={index}
                                    fullWidth
                                    variant={activeRegionTab === index ? 'contained' : 'outlined'}
                                    onClick={() => handleRegionButtonClick(index, r.id)}
                                    sx={{
                                        mt: index > 0 ? 1 : 0
                                    }}
                                >
                                    {r.region}
                                </Button>
                            )
                        })
                    }
                </Grid>
                <Grid item xs={12} sm={8} sx={{ height: '100%', }}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3, 
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box>
                            <TextField
                                fullWidth
                                type='number'
                                label='Order Age Cutoff'
                                placeholder='Number of days'
                                disabled={actionType === 'view'}
                                value={activeRegionProperties?.orderAgeCutoff || 0}
                                onChange={(e) => setActiveRegionProperties(p => {return {...p, orderAgeCutoff: e.target.value}})}
                            />
                            <TextField
                                fullWidth
                                type='number'
                                label='Days to Review'
                                placeholder='Number of days'
                                disabled={actionType === 'view'}
                                value={activeRegionProperties?.daysToReview || 0}
                                onChange={(e) => setActiveRegionProperties(p => {return {...p, daysToReview: e.target.value}})}
                                sx={{
                                    mt: 2
                                }}
                            />
                            <TextField
                                fullWidth
                                type='number'
                                label='Escalation Cutoff'
                                placeholder='Number of days'
                                disabled={actionType === 'view'}
                                value={activeRegionProperties?.escalationCutoff || 0}
                                onChange={(e) => setActiveRegionProperties(p => {return {...p, escalationCutoff: e.target.value}})}
                                sx={{
                                    mt: 2
                                }}
                            />
                            <TextField
                                fullWidth
                                disabled
                                type='text'
                                label='Modified By'
                                placeholder='Modified By'
                                value={activeRegionProperties?.modifiedBy || ''}
                                sx={{
                                    mt: 2
                                }}
                            />
                            <TextField
                                fullWidth
                                disabled
                                type='text'
                                label='Modified Date'
                                placeholder='Modified Date'
                                value={activeRegionProperties.modifiedDate ? format(new Date(activeRegionProperties?.modifiedDate), 'eeee, MMMM d, yyyy hh:mm a') : ''}
                                sx={{
                                    mt: 2
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {
                                actionType !== 'view' &&
                                <Button
                                    variant='contained'
                                    color='error'
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDiscardClick}
                                >
                                    Discard
                                </Button>
                            }
                            <Button
                                variant={actionType === 'view' ? 'outlined' : 'contained'}
                                color='success'
                                startIcon={actionType === 'view' ? <EditIcon /> : <SaveIcon />}
                                onClick={handleViewEditClick}
                                sx={{ ml: 2 }}
                            >
                                {actionType === 'view' ? 'Edit' : 'Save'}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Notif
                open={notifInfo.open}
                severity={notifInfo.severity}
                message={notifInfo.message}
                onCloseCallback={setNotifInfo}
            />
        </Container>
	);
};

export default Properties;
