import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './comps/Layout';
import { UseAppProvider } from './contexts/appContext';
import Home from './pages/Home';
import Admin from './pages/Admin';

function App() {
	return (
		<UseAppProvider>
			<Router>
				<Layout>
					<Routes>
						<Route exact path='/' element={<Home />} />
						<Route path='/admin' element={<Admin />} />
					</Routes>
				</Layout>
			</Router>
		</UseAppProvider>
	);
}

export default App;
